<template>
  <PdfView
    :pdfImages="widget.object.pdf_content.thumbnail_url"
    :pageDuration="widget.position.data.pageDuration"
    :imageMode="widget.position.data.imageMode"
    :style="style"
    position="absolute"
  />
</template>

<script>
  import playerMixins from '@/models/player';
  import PdfView from '@/components/content/PdfView.vue';

  import { simpleTypeMixin } from '@/helpers';

  export default {
    name: 'PdfWidget',

    mixins: [playerMixins, simpleTypeMixin],

    components: {
      PdfView,
    },

    props: {
      widget: {
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      style() {
        const style = this.defaultStyling(this.widget);

        return {
          ...style,
          position: 'absolute',
        };
      },

      layoutRotation() {
        return this.$store.getters.getPlayerRotation;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pdf-viewver {
    overflow: hidden;
  }
</style>
