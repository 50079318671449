<template>
  <div
    ref="layoutContainer"
    v-if="widgets && layout"
    class="layout-main-container"
    :style="{ backgroundColor: layout.settings.backgroundColor || '#FFFFFF' }"
  >
    <Layer v-for="widget in widgets" :key="widget.assoc_id" :widget="widget" />
  </div>
</template>

<script>
  import Layer from '@/components/player/Layer.vue';
  import { SET_SCREEN_SIZE } from '@/store/actions/player';

  export default {
    name: 'Layout',

    props: {
      layout: {
        type: Object,
        default: () => null,
      },
    },

    components: {
      Layer,
    },

    mounted() {
      this.setScreenSize();
    },

    watch: {
      layout() {
        this.setScreenSize();
      },
    },

    computed: {
      widgets() {
        return this.$store.state.player.widgets;
      },
    },

    methods: {
      setScreenSize() {
        if (
          this.$refs.layoutContainer &&
          this.$refs.layoutContainer.clientHeight &&
          this.$refs.layoutContainer.clientWidth
        ) {
          this.$nextTick(function () {
            this.$store.commit(SET_SCREEN_SIZE, {
              height: this.$refs.layoutContainer.clientHeight,
              width: this.$refs.layoutContainer.clientWidth,
            });
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layout-main-container {
    width: 100%;
    height: 100%;
  }
</style>
