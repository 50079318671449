import { getWidgetId, round } from '@/helpers/utils';
import { REFRESH_LAYOUT_STATUS } from '@/config/constants';
import {
  LAYOUT_DESIGNER_SELECT_WIDGET,
  LAYOUT_DESIGNER_UPDATE_WIDGET,
  SET_GUIDE_LINES,
  SET_REFRESHING_LAYOUT_STATE,
} from '@/store/actions/layoutDesigner';
import { restrictToBounds } from '@/helpers/draggable';

export default {
  data() {
    return {
      hideParent: false,
      keepZIndexUp: false,
      startPosition: null,
    };
  },

  watch: {
    isHorizontal() {
      this.updateVueDraggable();
    },
  },

  computed: {
    isHorizontal() {
      return this.$store.state.layoutDesigner.isLayoutHorizontal;
    },

    showGrid() {
      return this.$store.state.layoutDesigner.showLayoutGrid;
    },

    gridScale() {
      const { x, y } = this.$store.state.layoutDesigner?.layout.settings.grid || { x: 20, y: 20 };

      return this.showGrid ? [x * this.layoutScaleFactor, y * this.layoutScaleFactor] : null;
    },

    isSnapActive() {
      return this.$store.state.layoutDesigner.isSnapGridActive;
    },

    rotation() {
      return this.$store.getters.getLayoutRotation;
    },

    layoutDimensions() {
      return this.$store.getters.getLayoutDimensions;
    },

    screenDimensions() {
      return this.$store.getters.getScreenDimensions;
    },

    layoutScaleFactor() {
      return this.$store.getters.getResolution.scaleFactor;
    },

    isWidgetSelected() {
      if (!this.$store.state.layoutDesigner.selectedWidget) {
        return false;
      }

      const selectedWidgetId = getWidgetId(this.$store.state.layoutDesigner.selectedWidget);

      return selectedWidgetId === getWidgetId(this.widget);
    },

    isWidgetLocked() {
      return !!this.widget.position[this.rotation].isLocked;
    },

    isSavingLayout() {
      return this.$store.state.layoutDesigner.isSavingLayout;
    },
  },

  methods: {
    onWidgetDrag(x, y) {
      if (this.startPosition && x === this.startPosition.x && y === this.startPosition.y) {
        this.startPosition = null;
        this.setRefreshingStatus(REFRESH_LAYOUT_STATUS.STAND_BY);
        return;
      } // Avoids updating widget on simple selection

      this.startPosition = null;

      const { scaleFactor } = this.$store.getters.getResolution;
      const { width: layoutWidth, height: layoutHeight } = this.layoutDimensions;
      const { width: screenWidth, height: scrennHeight } = this.screenDimensions;

      const widgetToUpdate = { ...this.widget };

      widgetToUpdate.position[this.rotation].x = restrictToBounds(
        round((x * 100) / layoutWidth, 2),
        0,
        100,
      );
      widgetToUpdate.position[this.rotation].y = restrictToBounds(
        round((y * 100) / layoutHeight, 2),
        0,
        100,
      );
      widgetToUpdate.position[this.rotation].xPixels = restrictToBounds(
        round(x / scaleFactor, 2),
        0,
        screenWidth,
      );
      widgetToUpdate.position[this.rotation].yPixels = restrictToBounds(
        round(y / scaleFactor, 2),
        0,
        scrennHeight,
      );

      this.updateWidget(widgetToUpdate);
    },

    updateWidget(widget) {
      this.$store.commit(LAYOUT_DESIGNER_UPDATE_WIDGET, { widget, update: 'mixing' });
    },

    updateVueDraggable() {
      /* ---- THIS IS IMPORTANT---
        DRAGGABLE SAVES THE PARENT WIDTH AND HEIGHT WHEN THE COMPONENT IS LOADED
        AND THE ONLY WAY I FOUND TO UPDATE THOSE DIMENSIONS WAS FORCING MOUNTING
        THE DRAGGABLE COMPONENT AGAIN EVENT. EVERY OTHER WAY BREAKS THE WIDGET'S
        POSITIONING
      */
      this.hideParent = true;

      const _this = this;

      setTimeout(function () {
        _this.hideParent = false;
      }, 1);
    },

    onWidgetResize(x, y, width, height) {
      const { width: layoutWidth, height: layoutHeight } = this.layoutDimensions;
      const { width: screenWidth, height: scrennHeight } = this.screenDimensions;
      const { scaleFactor } = this.$store.getters.getResolution;
      const xScaling = 100 / layoutWidth;
      const yScaling = 100 / layoutHeight;

      const newPosition = {
        x: restrictToBounds(round(x * xScaling, 2), 0, 100),
        y: restrictToBounds(round(y * yScaling, 2), 0, 100),
        width: restrictToBounds(round(width * xScaling, 2), 0, 100),
        height: restrictToBounds(round(height * yScaling, 2), 0, 100),

        xPixels: restrictToBounds(round(x / scaleFactor, 2), 0, screenWidth),
        yPixels: restrictToBounds(round(y / scaleFactor, 2), 0, scrennHeight),
        widthPixels: restrictToBounds(round(width / scaleFactor, 2), 0, screenWidth),
        heightPixels: restrictToBounds(round(height / scaleFactor, 2), 0, scrennHeight),

        zIndex: this.widget.position[this.rotation].zIndex,
      };

      this.updateWidget({
        ...this.widget,
        position: {
          ...this.widget.position,
          [this.rotation]: {
            ...this.widget.position[this.rotation],
            ...newPosition,
          },
        },
      });
    },

    setRefreshingStatus(status) {
      this.$store.commit(SET_REFRESHING_LAYOUT_STATE, status);
    },

    getPosition(widget) {
      const { scaleFactor } = this.$store.getters.getResolution;
      const xScaling = this.layoutDimensions.width / 100;
      const yScaling = this.layoutDimensions.height / 100;

      const position = this.isHorizontal ? widget.position.horizontal : widget.position.vertical;
      const { usePixels } = position;
      const newPosition = {
        left: usePixels ? position.xPixels * scaleFactor : position.x * xScaling,
        top: usePixels ? position.yPixels * scaleFactor : position.y * yScaling,
        height: usePixels ? position.heightPixels * scaleFactor : position.height * yScaling,
        width: usePixels ? position.widthPixels * scaleFactor : position.width * xScaling,
      };

      return newPosition;
    },

    onSelected(widget) {
      this.keepZIndexUp = true;
      this.setRefreshingStatus(REFRESH_LAYOUT_STATUS.SELECTING);
      this.$store.commit(LAYOUT_DESIGNER_SELECT_WIDGET, widget);
    },

    onChangePosition(isMoving = true, x = null, y = null) {
      this.startPosition = isMoving ? { x, y } : null;

      const widgetStatus = isMoving ? REFRESH_LAYOUT_STATUS.MOVING : REFRESH_LAYOUT_STATUS.RESIZING;

      this.setRefreshingStatus(this.isWidgetLocked ? REFRESH_LAYOUT_STATUS.STAND_BY : widgetStatus);
    },

    getRefLineParams(linesData) {
      this.$store.commit(SET_GUIDE_LINES, linesData);
    },

    scaleLayoutValue(value) {
      const { scaleFactor } = this.$store.getters.getResolution;

      return scaleFactor * value;
    },
  },
};
