<template>
  <div class="image-container" :style="style">
    <img
      :src="backgroundImageUrl"
      :style="imageStyle"
      :alt="widget.position.name"
      @click.prevent="showImage"
    />
  </div>
</template>

<script>
  import playerMixins from '@/models/player';
  import { simpleTypeMixin } from '@/helpers';
  import config from '@/config';

  export default {
    name: 'ImageWidget',

    mixins: [playerMixins, simpleTypeMixin],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    computed: {
      style() {
        const style = this.defaultStyling(this.widget);

        return {
          ...style,
          opacity: Number(this.widget.position.opacity) / 100,
          position: 'absolute',
        };
      },

      layoutRotation() {
        return this.$store.getters.getPlayerRotation;
      },

      imageStyle() {
        return {
          objectFit: this.widget.position.imageMode,
        };
      },

      backgroundImageUrl() {
        return this.getEncodedURL(config.baseUrl, this.widget.object.item_url);
      },
    },

    methods: {
      showImage() {
        //this.$emit('show-enlarged', this.widget)
      },
    },
  };
</script>

<style lang="scss" scoped>
  .image-container {
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
