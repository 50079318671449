/* eslint-disable no-shadow */

// This handles users in your organisation.
//
import Vue from 'vue';

import {
  USER_REQUEST,
  USER_GET_ERROR,
  USER_GET_SUCCESS,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_UPLOAD_AVATAR,
  USER_ADD,
  USER_ADD_SUCCESS,
  USER_ADD_ERROR,
  USER_DELETE,
  USER_DELETE_ERROR,
  USER_DELETE_SUCCESS,
  USER_RESET_STATUS,
  ADMIN_USER_ADD,
  ADMIN_USER_ADD_SUCCESS,
  ADMIN_USER_ADD_ERROR,
  ADMIN_USERS_REQUEST,
  ADMIN_USERS_REQUEST_SUCCESS,
  ADMIN_USERS_REQUEST_ERROR,
  ADMIN_USER_UPDATE,
  ADMIN_USER_UPDATE_SUCCESS,
  ADMIN_USER_UPDATE_ERROR,
  ADMIN_USER_DELETE,
  ADMIN_USER_DELETE_SUCCESS,
  ADMIN_USER_DELETE_ERROR,
} from '../actions/user';

import {
  apiGetUser,
  apiAddUser,
  apiDeleteUser,
  apiUpdateUser,
  apiUpdateUserAvatar,
  adminApiPutUser,
  adminApiGetUsers,
  adminApiPostUser,
  adminApiDeleteUser,
} from '../../api/user';
import { getCloudfrontUrl } from '@/helpers/utils';

const state = {
  status: '',
  user: {},
  users: [],
  error: '',

  requestError: '',
  requestStatus: '',

  updateError: '',
  updateStatus: '',

  addError: '',
  addStatus: '',

  deleteError: '',
  deleteStatus: '',
};

const getters = {
  getUser: (state) => state.user,
  getUsers: (state) => state.users,
  userStatus: (state) => state.status,
  userErrorMsg: (state) => {
    if (state.error.length > 0) {
      return state.error;
    }
    return '';
  },

  getRequestUserStatus: (state) => state.requestStatus,
  getUpdateUserStatus: (state) => state.updateStatus,
  getAddUserStatus: (state) => state.addStatus,
  getDeleteUserStatus: (state) => state.deleteStatus,
};

const actions = {
  [USER_REQUEST]: ({ commit }, userId) => {
    commit(USER_REQUEST);
    const p = apiGetUser(userId);
    p.then((resp) => {
      commit(USER_GET_SUCCESS, resp);
    }).catch((error) => {
      commit(USER_GET_ERROR, error);
    });
    return p;
  },

  [USER_ADD]: ({ commit }, user) => {
    commit(USER_ADD);
    const p = apiAddUser(user);
    p.then((resp) => {
      commit(USER_ADD_SUCCESS, resp);
    }).catch((error) => {
      commit(USER_ADD_ERROR, error);
    });
    return p;
  },

  [USER_DELETE]: ({ commit }, userId) => {
    commit(USER_DELETE);
    const p = apiDeleteUser(userId);
    p.then(() => {
      commit(USER_DELETE_SUCCESS);
    }).catch((error) => {
      commit(USER_DELETE_ERROR, error);
    });
    return p;
  },

  [USER_UPDATE]: ({ commit }, user) => {
    commit(USER_UPDATE);
    const p = apiUpdateUser(user);
    p.then((resp) => {
      commit(USER_UPDATE_SUCCESS, resp);
    }).catch((error) => {
      commit(USER_UPDATE_ERROR, error);
    });
    return p;
  },

  [USER_UPLOAD_AVATAR]: ({ commit }, { user, avatar }) => {
    commit(USER_UPDATE);
    const p = apiUpdateUserAvatar({ ...user, avatar });
    p.then((resp) => {
      commit(USER_UPDATE_SUCCESS, resp);
    }).catch((error) => {
      commit(USER_UPDATE_ERROR, error);
    });
    return p;
  },

  [ADMIN_USER_ADD]: ({ commit }, userData) => {
    commit(ADMIN_USER_ADD);
    const p = adminApiPutUser(userData);
    p.then((resp) => {
      commit(ADMIN_USER_ADD_SUCCESS, resp);
    }).catch((error) => {
      commit(ADMIN_USER_ADD_ERROR, error);
    });
    return p;
  },

  [ADMIN_USERS_REQUEST]: ({ commit }, orgId) => {
    commit(ADMIN_USERS_REQUEST);
    const p = adminApiGetUsers(orgId);
    p.then((resp) => {
      commit(ADMIN_USERS_REQUEST_SUCCESS, resp);
    }).catch((error) => {
      commit(ADMIN_USERS_REQUEST_ERROR, error);
    });
    return p;
  },

  [ADMIN_USER_UPDATE]: ({ commit }, { userId, userData }) => {
    commit(ADMIN_USER_UPDATE);
    const p = adminApiPostUser(userId, userData);
    p.then((resp) => {
      commit(ADMIN_USER_UPDATE_SUCCESS, resp);
    }).catch((error) => {
      commit(ADMIN_USER_UPDATE_ERROR, error);
    });
    return p;
  },

  [ADMIN_USER_DELETE]: ({ commit }, userId) => {
    commit(ADMIN_USER_DELETE);
    const p = adminApiDeleteUser(userId);
    p.then((resp) => {
      commit(ADMIN_USER_DELETE_SUCCESS, resp);
    }).catch((error) => {
      commit(ADMIN_USER_DELETE_ERROR, error);
    });
    return p;
  },
};

const mutations = {
  [USER_RESET_STATUS]: (state) => {
    state.requestStatus = '';
    state.updateStatus = '';
    state.addStatus = '';
    state.deleteStatus = '';
  },
  [USER_ADD]: (state) => {
    state.addStatus = 'loading';
  },
  [USER_ADD_SUCCESS]: (state) => {
    state.addStatus = 'success';
  },
  [USER_ADD_ERROR]: (state, error) => {
    state.addError = String(error);
    state.addStatus = 'error';
  },
  [USER_REQUEST]: (state) => {
    state.requestStatus = 'loading';
  },
  [USER_GET_SUCCESS]: (state, resp) => {
    if (resp.data.constructor === Array) {
      // We were returned a list of users
      Vue.set(
        state,
        'users',
        resp.data.map((user) => {
          return {
            ...user,
            avatar_url: getCloudfrontUrl(user.avatar_url),
          };
        }),
      );
    } else {
      // We were returned one user object
      Vue.set(state, 'user', {
        ...resp.data,
        avatar_url: getCloudfrontUrl(resp.data.avatar_url),
      });
    }
    state.requestStatus = 'success';
  },
  [USER_GET_ERROR]: (state, error) => {
    state.requestError = String(error);
    state.requestStatus = 'error';
  },
  [USER_UPDATE]: (state) => {
    state.updateStatus = 'loading';
  },
  [USER_UPDATE_SUCCESS]: (state, resp) => {
    Vue.set(state, 'user', {
      ...resp.data,
      avatar_url: getCloudfrontUrl(resp.data.avatar_url),
    });
    state.updateStatus = 'success';
  },
  [USER_UPDATE_ERROR]: (state, error) => {
    state.updateError = String(error);
    state.updateStatus = 'error';
  },
  [USER_DELETE]: (state) => {
    state.deleteStatus = 'loading';
  },
  [USER_DELETE_SUCCESS]: (state) => {
    state.deleteStatus = 'sucess';
  },
  [USER_DELETE_ERROR]: (state, error) => {
    state.deleteError = String(error);
    state.deleteStatus = 'error';
  },

  [ADMIN_USER_ADD]: (state) => {
    state.sddStatus = 'loading';
  },
  [ADMIN_USER_ADD_SUCCESS]: (state) => {
    state.addStatus = 'success';
  },
  [ADMIN_USER_ADD_ERROR]: (state, error) => {
    state.addError = String(error);
    state.addStatus = 'error';
  },

  [ADMIN_USERS_REQUEST]: (state) => {
    state.requestStatus = 'loading';
  },
  [ADMIN_USERS_REQUEST_SUCCESS]: (state, resp) => {
    Vue.set(
      state,
      'users',
      resp.data.map((user) => {
        return {
          ...user,
          avatar_url: getCloudfrontUrl(user.avatar_url),
        };
      }),
    );
    state.requestStatus = 'success';
  },
  [ADMIN_USERS_REQUEST_ERROR]: (state, error) => {
    state.requestError = String(error);
    state.requestStatus = 'error';
  },

  [ADMIN_USER_UPDATE]: (state) => {
    state.updateStatus = 'loading';
  },
  [ADMIN_USER_UPDATE_SUCCESS]: (state) => {
    state.updateStatus = 'success';
  },
  [ADMIN_USER_UPDATE_ERROR]: (state, error) => {
    state.updateError = error;
    state.updateStatus = 'error';
  },

  [ADMIN_USER_DELETE]: (state) => {
    state.deleteStatus = 'loading';
  },
  [ADMIN_USER_DELETE_SUCCESS]: (state) => {
    state.deleteStatus = 'success';
  },
  [ADMIN_USER_DELETE_ERROR]: (state, error) => {
    state.deleteError = error;
    state.deleteStatus = 'error';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
