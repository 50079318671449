<template>
  <div>
    <video
      loop
      muted
      :ref="`playlist_video_${slide.item_id}`"
      :class="['playlist-slide-video']"
      :style="videoStyle"
    >
      <source :src="getEncodedURL(baseUrl, slide.item_url)" :type="slide.item_type" />
    </video>
  </div>
</template>

<script>
  import { simpleTypeMixin } from '@/helpers';
  import config from '@/config';

  export default {
    props: ['slide', 'isPlaying'],

    mixins: [simpleTypeMixin],

    mounted() {
      this.toggleVideo();
    },

    computed: {
      videoStyle() {
        return {
          objectFit: this.slide.imageMode || 'cover',
        };
      },
    },

    methods: {
      playVideo() {
        this.$refs[`playlist_video_${this.slide.item_id}`].currentTime = 0;
        this.$refs[`playlist_video_${this.slide.item_id}`].play();
      },

      pauseVideo() {
        this.$refs[`playlist_video_${this.slide.item_id}`].pause();
      },

      toggleVideo() {
        if (this.isPlaying) {
          this.playVideo();
        } else {
          this.pauseVideo();
        }
      },
    },

    watch: {
      isPlaying() {
        this.toggleVideo();
      },
    },
  };
</script>
