<template>
  <component
    @show-enlarged="showEnlarged"
    :is="`${node.data.object.type}-widget`"
    :widget="node.data"
  />
</template>

<script>
  import LayerWidget from '@/components/templates/viewer/templateWidgets/LayerWidget.vue';
  import TextWidget from '@/components/templates/viewer/templateWidgets/TextWidget.vue';
  import ClockWidget from '@/components/templates/viewer/templateWidgets/ClockWidget.vue';
  import ButtonWidget from '@/components/templates/viewer/templateWidgets/ButtonWidget.vue';
  import ImageWidget from '@/components/templates/viewer/templateWidgets/ImageWidget.vue';
  import AppWidget from '@/components/templates/viewer/templateWidgets/AppWidget.vue';
  import VideoWidget from '@/components/templates/viewer/templateWidgets/VideoWidget.vue';
  import PdfWidget from '@/components/templates/viewer/templateWidgets/PdfWidget.vue';
  import RichtextWidget from '@/components/templates/viewer/templateWidgets/RichTextWidget.vue';

  export default {
    name: 'WidgetNode',
    props: {
      node: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    methods: {
      showEnlarged(w) {
        this.$emit('show-enlarged', w);
      },
    },
    components: {
      ImageWidget,
      LayerWidget,
      TextWidget,
      ClockWidget,
      ButtonWidget,
      AppWidget,
      VideoWidget,
      PdfWidget,
      RichtextWidget,
    },
  };
</script>

<style scoped></style>
