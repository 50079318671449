export const SCREENS_FETCH = 'SCREENS_FETCH';
export const SCREENS_FETCH_ERROR = 'SCREENS_FETCH_ERROR';
export const SCREENS_FETCH_SUCCESS = 'SCREENS_FETCH_SUCCESS';

export const SCREEN_GROUPS_FETCH = 'SCREEN_GROUPS_FETCH';
export const GROUP_SCREEN_GROUP_SCREENS = 'GROUP_SCREEN_GROUP_SCREENS';
export const GROUP_SCREENS_FETCH = 'GROUP_SCREENS_FETCH';
export const SCREEN_GROUPS_FETCH_ERROR = 'SCREEN_GROUPS_FETCH_ERROR';
export const SCREEN_GROUPS_FETCH_SUCCESS = 'SCREEN_GROUPS_FETCH_SUCCESS';

export const SCREEN_GROUP_UPDATE = 'SCREEN_GROUP_UPDATE';

export const SCREEN_GROUP_DELETE = 'SCREEN_GROUP_DELETE';

export const SCREENS_ROTATE = 'SCREENS_ROTATE';
export const SCREENS_ROTATE_SUCCESS = 'SCREENS_ROTATE_SUCCESS';

export const SCREENS_RESTART = 'SCREENS_RESTART';
export const SCREENS_RESTART_SUCCESS = 'SCREENS_RESTART_SUCCESS';

export const SCREEN_DELETE = 'SCREEN_DELETE';
export const SCREEN_DELETE_SUCCESS = 'SCREEN_DELETE_SUCCESS';

export const SCREENS_FILTERS_SET = 'SCREENS_FILTERS_SET';

export const SCREENS_ADD_GROUP = 'SCREENS_ADD_GROUP';
export const SCREENS_ADD_GROUP_SUCCESS = 'SCREENS_ADD_GROUP_SUCCESS';

export const SCREENS_ADD = 'SCREENS_ADD';
export const SCREENS_ADD_SUCCESS = 'SCREENS_ADD_SUCCESS';
export const SCREENS_ADD_ERROR = 'SCREENS_ADD_ERROR';

export const SCREEN_UPDATE = 'SCREEN_UPDATE';
export const SCREEN_UPDATE_SUCCESS = 'SCREEN_UPDATE_SUCCESS';

export const SCREEN_GET = 'SCREEN_GET';
export const SCREEN_GET_SUCCESS = 'SCREEN_GET_SUCCESS';

export const SCREEN_ADD_LAYOUT = 'SCREEN_ADD_LAYOUT';
export const SCREEN_ADD_LAYOUT_SUCCESS = 'SCREEN_ADD_LAYOUT_SUCCESS';

export const SCREEN_LAYOUT_FETCH = 'SCREEN_LAYOUT_FETCH';

export const SCREEN_GET_ALL_LICENSES = 'SCREEN_GET_ALL_LICENSES';
// Reset

export const SCREENS_RESET = 'SCREENS_RESET';
export const SET_SCREEN = 'SET_SCREEN';
export const SET_SCREEN_GROUP = 'SET_SCREEN_GROUP';

// Screen status
export const SCREENS_STATUS_FETCH = 'SCREENS_STATUS_FETCH';
export const SCREENS_STATUS_FETCH_SUCCESS = 'SCREENS_STATUS_FETCH_SUCCESS';
export const SCREENS_STATUS_FETCH_ERROR = 'SCREENS_STATUS_FETCH_ERROR';

export const SCREENS_STATUS_UPDATE = 'SCREENS_STATUS_UPDATE';
