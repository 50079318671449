export const LOAD_LAYOUT = 'LOAD_LAYOUT';
export const LOAD_LAYOUT_CHILDREN = 'LOAD_LAYOUT_CHILDREN';
export const SET_LAYOUT_CHILDREN = 'SET_LAYOUT_CHILDREN ';
export const CREATE_LAYOUT = 'CREATE_LAYOUT';
export const CLONE_LAYOUT = 'CLONE_LAYOUT';
export const RESET_LAYOUT = 'RESET_LAYOUT';
export const SET_SAVE_LAYOUT = 'SET_SAVE_LAYOUT';
export const SET_SAVING_LAYOUT = 'SET_SAVING_LAYOUT';

// WIDGETS
export const CREATE_LAYER = 'CREATE_LAYER';
export const CREATE_COMMON_WIDGET = 'CREATE_COMMON_WIDGET';
export const UPDATE_WIDGET_THUMBNAIL = 'UPDATE_WIDGET_THUMBNAIL';
export const REMOVE_DELETED_CHILDREN = 'REMOVE_DELETED_CHILDREN';
export const REPLACE_DUMMMY_CHILD = 'REPLACE_DUMMMY_CHILD';
export const LAYOUT_REMOVE_MODIFIED_WIDGET = 'LAYOUT_REMOVE_MODIFIED_WIDGET';
export const LAYOUT_REPLACE_CHILD_ITEM = 'LAYOUT_REPLACE_CHILD_ITEM';

export const LAYOUT_DESIGNER_ADD_WIDGET = 'LAYOUT_DESIGNER_ADD_WIDGET';
export const LAYOUT_DESIGNER_DUPLICATE_WIDGET = 'LAYOUT_DESIGNER_DUPLICATE_WIDGET';

// DUMMY WIDGETS
export const CREATE_DUMMY_LAYER = 'CREATE_DUMMY_LAYER';
export const LAYOUT_ADD_REPLACED_WIDGET = 'LAYOUT_ADD_REPLACED_WIDGET';
export const LAYOUT_DELETE_REPLACED_WIDGET = 'LAYOUT_DELETE_REPLACED_WIDGET';

// Playlists
export const SET_PLAYLIST_CHILDREN = 'SET_PLAYLIST_CHILDREN';
export const SET_PLAYLIST_UPDATED = 'SET_PLAYLIST_UPDATED';
export const UPDATE_PLAYLIST_ITEM = 'UPDATE_PLAYLIST_ITEM';
export const UPDATE_SAVED_PLAYLIST_ITEM = 'UPDATE_SAVED_PLAYLIST_ITEM';
export const REMOVE_PLAYLIST_ITEM = 'REMOVE_PLAYLIST_ITEM';
export const REMOVE_FROM_PLAYLIST_ITEMS_CHANGES = 'REMOVE_FROM_PLAYLIST_ITEMS_CHANGES';

// mutations for internal use of editor
export const LAYOUT_DESIGNER_TO_SETTING_TAB = 'LAYOUT_DESIGNER_TO_SETTING_TAB';
export const LAYOUT_DESIGNER_SET_LAYOUT = 'LAYOUT_DESIGNER_SET_LAYOUT';
export const LAYOUT_DESIGNER_TOGGLE_GRID = 'LAYOUT_DESIGNER_TOGGLE_GRID';
export const LAYOUT_DESIGNER_TOGGLE_SNAP = 'LAYOUT_DESIGNER_TOGGLE_SNAP';

// shared between both designer and editor
export const LAYOUT_DESIGNER_DELETE_WIDGET = 'LAYOUT_DESIGNER_DELETE_WIDGET';

// mutations from designer to editor
export const LAYOUT_DESIGNER_SELECT_WIDGET = 'LAYOUT_DESIGNER_SELECT_WIDGET';
export const LAYOUT_DESIGNER_SET_ZINDEX = 'LAYOUT_DESIGNER_SET_ZINDEX';
export const SET_PLAYLIST_ITEMS_PANEL = 'SET_PLAYLIST_ITEMS_PANEL';

// mutations from editor to designer
export const LAYOUT_DESIGNER_UPDATE_WIDGET = 'LAYOUT_DESIGNER_UPDATE_WIDGET';
export const LAYOUT_DESIGNER_DESELECT_WIDGET = 'LAYOUT_DESIGNER_DESELECT_WIDGET';

export const LAYOUT_ROTATION = 'LAYOUT_ROTATION';
export const SET_REFRESHING_LAYOUT_STATE = 'SET_REFRESHING_LAYOUT_STATE';
export const SET_GUIDE_LINES = 'SET_GUIDE_LINES';
