<template>
  <div>
    <img :src="backgroundImageUrl" :style="imageStyle" :alt="slide.name" />
  </div>
</template>

<script>
  import { simpleTypeMixin } from '@/helpers';
  import config from '@/config';

  export default {
    name: 'ImageSlide',

    props: ['slide'],

    mixins: [simpleTypeMixin],

    computed: {
      imageStyle() {
        return {
          width: '100%',
          height: '100%',
          backgroundPosition: 'center',
          backgroundSize: 'auto',
          backgroundRepeat: 'no-repeat',
          objectFit: this.slide.imageMode,
        };
      },

      backgroundImageUrl() {
        return this.getEncodedURL(config.baseUrl, this.slide.item_url);
      },
    },
  };
</script>
