import config from '../config';
import axios from 'axios';

axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token');

export const apiGetPlaylist = (playlist_id) => {
  const apiUrl = playlist_id
    ? `${config.apiUrl}userapi/playlist/${playlist_id}`
    : `${config.apiUrl}userapi/playlist`;

  return axios.get(apiUrl);
};

export const apiCreatePlaylist = (playlistData) => {
  const apiUrl = `${config.apiUrl}userapi/area/create`;

  return axios.post(apiUrl, playlistData);
};

export const apiUpdatePlaylist = (playlistId, data) => {
  const apiUrl = `${config.apiUrl}userapi/playlist/${playlistId}`;

  return axios.post(apiUrl, data);
};

export const apiDeletePlaylist = (playlist_id) => {
  const apiUrl = `${config.apiUrl}userapi/playlist/${playlist_id}`;

  return axios.delete(apiUrl);
};

// PlaylistItem
export const apiGetPlaylistItem = (playlist_id, params = {}) => {
  const apiUrl = `${config.apiUrl}userapi/playlist/${playlist_id}/item`;

  return axios.get(apiUrl, { params });
};

export const apiGetScreenPlaylistItem = (playlistId, screenId, showHiddenItems = false) => {
  const apiUrl = `${config.apiUrl}userapi/screens/${screenId}/playlist/${playlistId}/children?show_delete_overrides=${showHiddenItems}`;

  return axios.get(apiUrl);
};

export const apiGetScreenGroupPlaylistItem = (
  playlistId,
  groupId,
  showHiddenItems = false,
  layout = '',
) => {
  const apiUrl = `${config.apiUrl}userapi/screen-group/${groupId}/playlist/${playlistId}/children?show_delete_overrides=${showHiddenItems}&layout=${layout}`;

  return axios.get(apiUrl);
};

export const apiPutPlaylistItem = (playlistId, itemData) => {
  const apiUrl = `${config.apiUrl}userapi/playlist/${playlistId}/item`;

  return axios.put(apiUrl, itemData);
};

export const apiCreateScreenPlaylistItemOverride = (items) => {
  const apiUrl = `${config.apiUrl}userapi/playlist-overrides`;

  return axios.post(apiUrl, items);
};

export const apiCreateScreenGroupPlaylistItemOverride = (items) => {
  const apiUrl = `${config.apiUrl}userapi/playlist-overrides/screen-group`;

  return axios.post(apiUrl, items);
};

export const apiUpdateScreenPlaylistItemOverride = (updateData) => {
  const apiUrl = `${config.apiUrl}userapi/playlist-overrides`;

  return axios.put(apiUrl, updateData);
};

export const apiUpdateScreenGroupPlaylistItemOverride = (updateData) => {
  const apiUrl = `${config.apiUrl}userapi/playlist-overrides/screen-group`;

  return axios.put(apiUrl, updateData);
};

export const apiPostPlaylistItem = (playlist_id, itemList) => {
  const apiUrl = `${config.apiUrl}userapi/playlist/${playlist_id}/item`;

  return axios.post(apiUrl, itemList);
};

export const apiDeletePlaylistItem = (playlist_id, assoc_id) => {
  const apiUrl = `${config.apiUrl}userapi/playlist/${playlist_id}/item/${assoc_id}`;

  return axios.delete(apiUrl);
};

export const apiAddAreaItems = (playlistId, data) => {
  const apiUrl = `${config.apiUrl}userapi/area/items/${playlistId}`;

  return axios.post(apiUrl, data);
};

export const apiDeleteAreaItems = (playlist_id, items) => {
  const itemsParam = items.map((item) => `items=${item}`).join('&');
  const apiUrl = `${config.apiUrl}userapi/area/items/${playlist_id}?${itemsParam}`;

  return axios.delete(apiUrl);
};

export const apiDeleteScreenPlaylistItemOverride = (screenId, playlistId, overrideId = '') => {
  const apiUrl = `${config.apiUrl}userapi/playlist-overrides/${screenId}?playlist_id=${playlistId}&override_id=${overrideId}`;
  return axios.delete(apiUrl);
};

export const apiDeleteScreenGroupPlaylistItemOverride = (groupId, playlistId, overrideId = '') => {
  const apiUrl = `${config.apiUrl}userapi/playlist-overrides/screen-group/${groupId}?playlist_id=${playlistId}&override_id=${overrideId}`;

  return axios.delete(apiUrl);
};
