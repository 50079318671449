import axios from 'axios';
import config from '@/config';

export async function apiScreenContentChange(screenId: number): Promise<void> {
  await axios.put(
    `${config.apiUrl}userapi/screen-content-management/screen/${screenId}/content-change`,
  );
}

export async function apiScreenGroupContentChange(
  screenGroupId: number,
  layoutId: string,
  screenIds?: number[],
): Promise<void> {
  await axios.put(
    `${config.apiUrl}userapi/screen-content-management/screen-group/${screenGroupId}/content-change`,
    { layout_id: layoutId, screen_ids: screenIds },
  );
}
