// widgets: hash  { [wid]: widget }
// output: hash   { [wid]: [list of all children wid sorted by their zIndex] }
export default function buildWidgetTree(widgets, rootName = 'root') {
  const tree = {};
  Object.values(widgets).forEach((widget) => {
    const childWid = widget.wid;
    if (!tree[childWid]) tree[childWid] = [];

    let parentWid = widget.parentWid || rootName;
    if (tree[parentWid]) {
      tree[parentWid].push(childWid);
    } else {
      tree[parentWid] = [childWid];
    }
  });

  const sortedTrees = {};
  // sort children by z-index
  Object.entries(tree).forEach(([parent, children]) => {
    sortedTrees[parent] = _.sortBy(
      children.map((childWid) => widgets[childWid]),
      'zIndex',
    ).map((w) => w.wid);
  });

  return sortedTrees;
}
