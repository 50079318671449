import {
  ViewerStyleBackground,
  ViewerStyleDeparture,
  ViewerStyleLine,
  ViewerStyleTitle,
} from '@/types/widgets/trafiklabWidget/trafiklabWidget';

export const DEFAULT_TRAFIKLAB_VIEWER_STYLE = Object.freeze({
  title: {
    fontType: 'Arial',
    fontColor: '#000000',
    fontSize: 16,
  } as ViewerStyleTitle,
  departures: {
    fontType: 'Arial',
    fontColor: '#000000',
    fontSize: 12,
  } as ViewerStyleDeparture,
  background: {
    color: '#FFFFFF',
    transparency: 0,
  } as ViewerStyleBackground,
  line: {
    fontColor: '#FFFFFF',
    backgroundColor: '#E3003A',
  } as ViewerStyleLine,
  evenDeparturesBackground: '#FFFFFF',
  oddDeparturesBackground: '#EAEAEA',
});
