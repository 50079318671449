/* eslint-disable no-shadow */
// This is everything for handling login and the currently logged in user.
//
import moment from 'moment';
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SET_VIEW_AS_USER,
  VIEW_AS_USER,
  SET_SUPER_ADMIN,
  VIEW_AS_USER_SUCCESS,
  // VIEW_AS_USER_ERROR,
  VIEW_AS_USER_EXIT,
  VIEW_AS_USER_EXIT_SUCCESS,
  VIEW_AS_USER_EXIT_ERROR,
  SET_USER_LOCAL_ADMIN,
} from '../actions/auth';

import apiLogin, { apiGetLoginAsUser, apiLoginAsUser, apiLoginAsUserExit } from '../../api/auth';
import { getCloudfrontUrl } from '@/helpers/utils';

function logoutUser() {
  localStorage.removeItem('user-username');
  localStorage.removeItem('user-user_id');
  localStorage.removeItem('user-created');
  localStorage.removeItem('user-organisation');
  localStorage.removeItem('user-is_enabled');
  localStorage.removeItem('user-is_administrator');
  localStorage.removeItem('user-is_local_administrator');
  localStorage.removeItem('user-session_token');
  localStorage.removeItem('user-session_expires');
  localStorage.removeItem('user-name');
  localStorage.removeItem('user-superadmintoken');
  localStorage.removeItem('user-superadminid');
  localStorage.removeItem('user-viewasuser');
  localStorage.removeItem('user-avatar_url');
}

function loginUser(userData) {
  localStorage.setItem('user-username', userData.username);
  localStorage.setItem('user-name', userData.name);
  localStorage.setItem('user-user_id', userData.user_id);
  localStorage.setItem('user-organisation', userData.organisation);
  localStorage.setItem('user-created', userData.created);
  localStorage.setItem('user-is_enabled', userData.is_enabled);
  localStorage.setItem('user-session_token', userData.session_token);
  localStorage.setItem('user-session_expires', userData.session_expires);
  localStorage.setItem('user-avatar_url', getCloudfrontUrl(userData.avatar_url));

  if (userData.is_administrator) {
    localStorage.setItem('user-is_administrator', userData.is_administrator);
  }

  if (userData.is_local_administrator) {
    localStorage.setItem('user-is_local_administrator', userData.is_local_administrator);
  }
}

function setSuperAdmin(superAdmin) {
  localStorage.setItem('user-superadmintoken', superAdmin.session_token);
  localStorage.setItem('user-superadminid', superAdmin.user_id);
}

function setViewAsUser(value) {
  localStorage.setItem('user-viewasuser', JSON.stringify(value));
}

function setAdminValues(is_local_administrator: boolean) {
  localStorage.setItem('user-is_local_administrator', is_local_administrator.toString());
}

function getViewAsUser() {
  return JSON.parse(localStorage.getItem('user-viewasuser'));
}

function sessionExpired(expiresDate) {
  const expDate = moment(expiresDate);
  if (moment().isAfter(expDate)) {
    return true;
  }
  return false;
}

const initialState = {
  status: '',
  error: '',

  // User data returned from backend
  user_id: localStorage.getItem('user-user_id') || '',
  username: localStorage.getItem('user-username') || '',
  name: localStorage.getItem('user-name') || '',
  organisation: localStorage.getItem('user-organisation') || '',
  organisation_name: localStorage.getItem('user-organisation-name') || '',
  created: localStorage.getItem('user-created') || '',
  is_enabled: localStorage.getItem('user-is_enabled') === 'true',
  is_administrator: localStorage.getItem('user-is_administrator') === 'true',
  is_local_administrator: localStorage.getItem('user-is_local_administrator') === 'true',
  session_token: localStorage.getItem('user-session_token') || '',
  session_expires: localStorage.getItem('user-session_expires') || '',
  avatar_url: localStorage.getItem('user-avatar_url') || '',

  superAdminToken: localStorage.getItem('user-superadmintoken') || '',
  superAdminId: localStorage.getItem('user-superadminid') || '',
  // Temporal "View As" user data:
  viewAsUser: getViewAsUser() || '',
};

const getters = {
  isAuthenticated: (state) => {
    if (sessionExpired(state.session_expires)) {
      logoutUser();
      return false;
    }
    return !!state.session_token;
  },

  isAdministrator: (state) => state.is_administrator,

  isOrganizationAdmin: (state) => state.is_local_administrator,

  isViewAsModeActive: (state) => !!state.viewAsUser,

  getUserId: (state) => state.user_id,

  authStatus: (state) => state.status,

  getCurrentUser: (state) => {
    const user = state;

    return {
      user_id: user.user_id,
      username: user.username,
      organisation: user.organisation,
      avatar_url: user.avatar_url,
      created: user.created,
      is_enabled: user.is_enabled,
      is_administrator: user.is_administrator,
      is_local_administrator: user.is_local_administrator,
      session_token: user.session_token,
      session_expires: user.session_expires,
    };
  },
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) =>
    new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      apiLogin(user)
        .then((resp) => {
          commit(AUTH_SUCCESS, resp.data);
          dispatch('ORGANISATION_REQUEST').then(() => {
            resolve(resp);
          });
        })
        .catch((error) => {
          commit(AUTH_ERROR, error);
          reject(error);
        });
    }),

  [AUTH_LOGOUT]: ({ commit }) =>
    new Promise((resolve) => {
      commit(AUTH_LOGOUT);
      logoutUser();
      resolve();
      window.location.href = '/';
    }),

  [VIEW_AS_USER]: ({ commit, state }, userData) => {
    const { isAdministrator } = getters;

    return new Promise((resolve, reject) => {
      let superAdmin = {
        session_token: state.session_token,
        user_id: state.user_id,
      };

      // Check if the current user is admin
      if (!isAdministrator(state) && state.superAdminToken) {
        superAdmin = {
          session_token: state.superAdminToken,
          user_id: state.superAdminId,
        };
      }

      apiLoginAsUser(userData.user_id, superAdmin.session_token)
        .then((resp) => {
          // Get the user data
          apiGetLoginAsUser(resp.user_id, resp.session_token)
            .then((user) => {
              commit(VIEW_AS_USER_SUCCESS, { sessionData: resp, user });
              commit(SET_SUPER_ADMIN, superAdmin);
              resolve(user);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  [VIEW_AS_USER_EXIT]: ({ commit, state }) =>
    new Promise((resolve, reject) => {
      if (!state.superAdminToken) {
        reject();
        return;
      }
      apiLoginAsUserExit(state.session_token)
        .then(() => {
          apiGetLoginAsUser(state.superAdminId, state.superAdminToken)
            .then((user) => {
              commit(VIEW_AS_USER_EXIT_SUCCESS, user);
              resolve(user);
            })
            .catch((error) => {
              reject(error);
            });
          // commit(VIEW_AS_USER_EXIT_SUCCESS);
          // resolve(resp);
        })
        .catch((error) => {
          commit(VIEW_AS_USER_EXIT_ERROR, error);
        });
    }),

  [SET_USER_LOCAL_ADMIN]: ({ commit }, userData) => {
    commit(SET_USER_LOCAL_ADMIN, userData);
  },
};

const mutations = {
  [SET_VIEW_AS_USER]: (state, selectedUserData) => {
    setViewAsUser(selectedUserData);
    state.viewAsUser = selectedUserData;
  },
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [AUTH_SUCCESS]: (state, userData) => {
    loginUser(userData, state);
    state.status = 'success';
  },
  [AUTH_ERROR]: (state, error) => {
    state.error = String(error);
    logoutUser();
    state.status = 'error';
  },
  [AUTH_LOGOUT]: () => {
    logoutUser();
    window.location.href = '/';
  },
  [VIEW_AS_USER_SUCCESS]: (state, payload) => {
    const { user, sessionData } = payload;

    // eslint-disable-next-line camelcase
    const { session_token, session_expires } = sessionData;
    logoutUser();
    loginUser(
      {
        ...user,
        session_token,
        session_expires,
      },
      state,
    );
    setViewAsUser(user);
    state.viewAsUser = user;
    state.session_token = session_token;
  },
  [VIEW_AS_USER_EXIT_SUCCESS]: (state, user) => {
    const superAdminSessionToken = state.superAdminToken;

    logoutUser();
    loginUser({ ...user, session_token: superAdminSessionToken }, state);
  },
  [SET_SUPER_ADMIN]: (state, superAdmin) => {
    setSuperAdmin(superAdmin);

    state.superAdminToken = superAdmin.session_token;
    state.superAdminId = superAdmin.user_id;
  },
  [SET_USER_LOCAL_ADMIN]: (state, userData) => {
    const { is_local_administrator } = userData;
    setAdminValues(is_local_administrator);
    state.is_local_administrator = is_local_administrator;
  },
};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};
