import config from '../config'
import axios from 'axios'

axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token')

export const apiGetOrganisation = () => new Promise((resolve, reject) => {
  axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token')
  var apiUrl = `${config.apiUrl}userapi/organisation`
  //console.log(`GET ${apiUrl}`)
  var p = axios.get(apiUrl).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
  return p
})

export const apiUpdateOrganisation = (organisation) => new Promise((resolve, reject) => {
  var apiUrl = `${config.apiUrl}userapi/organisation`
  //console.log(`POST ${apiUrl}`)
  var p = axios.post(apiUrl, organisation).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
  return p
})

export const adminApiGetOrganisations = () => new Promise((resolve, reject) => {
  var apiUrl = `${config.apiUrl}adminapi/organisation`
  axios.get(apiUrl).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
})

export const adminApiGetOrganisation = (orgId) => new Promise((resolve, reject) => {
  var apiUrl = `${config.apiUrl}adminapi/organisation/${orgId}`
  axios.get(apiUrl).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
})

export const adminApiPutOrganisation = (orgData) => new Promise((resolve, reject) => {
  var apiUrl = `${config.apiUrl}adminapi/organisation`
  axios.put(apiUrl, orgData).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
})

export const adminApiDeleteOrganisation = (orgId) => new Promise((resolve, reject) => {
  var apiUrl = `${config.apiUrl}adminapi/organisation/${orgId}`
  axios.delete(apiUrl).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
})

export const adminApiPostOrganisation = (orgId, orgData) => new Promise((resolve, reject) => {
  var apiUrl = `${config.apiUrl}adminapi/organisation/${orgId}`

  axios.post(apiUrl, orgData).then(resp => {
    resolve(resp)
  }).catch(error => {
    reject(error)
  })
})

export const getLicenses = (filters = {}) => new Promise((resolve, reject) => {
  const url = new URL(`userapi/organisation/licenses`, config.apiUrl);

  for(const param in filters) {
    url.searchParams.append(param, filters[param]);
  }
  axios.get(url.toString())
    .then(res => resolve(res))
    .catch(err => reject(err));
});

export const getOrganisationLicenses = (organisationId, filters = {}) => new Promise((resolve, reject) => {
  const url = new URL(`adminapi/organisation/${organisationId}/licenses`, config.apiUrl);

  for(const param in filters) {
    url.searchParams.append(param, filters[param]);
  }
  axios.get(url.toString())
    .then(res => resolve(res))
    .catch(err => reject(err));
});

export const createLicense = (organisationId, data) => new Promise((resolve, reject) => {
  const url = new URL(`adminapi/organisation/${organisationId}/licenses`, config.apiUrl);
  axios.post(url.toString(), data)
    .then(res => resolve(res))
    .catch(err => reject(err));
});

export const createBulkLicense = (organisationId, data) => new Promise((resolve, reject) => {
  const url = new URL(`adminapi/organisation/${organisationId}/licenses/bulk-create`, config.apiUrl);
  const reqBody = Array.isArray(data) ? {licenses: data} : data;

  axios.post(url.toString(), reqBody)
    .then(res => resolve(res))
    .catch(err => reject(err));
});

export const deleteLicenses = (organisationId, licenseIds) => new Promise((resolve, reject) => {
  const url = new URL(`adminapi/organisation/${organisationId}/licenses/bulk-delete`, config.apiUrl);
  const data = {
    license_ids: licenseIds
  }
  axios.delete(url.toString(), {data})
    .then(res => resolve(res))
    .catch(err => reject(err));
});

export const updateLicense = (organisationId, licenseId, data) => new Promise((resolve, reject) => {
  const url = new URL(`adminapi/organisation/${organisationId}/licenses/${licenseId}`, config.apiUrl);
  axios.patch(url.toString(), data)
    .then(res => resolve(res))
    .catch(err => reject(err));
});

export const updateLicenses = (organisationId, licenseIds, data) => new Promise((resolve, reject) => {
  const url = new URL(`adminapi/organisation/${organisationId}/licenses/bulk-update`, config.apiUrl);
  data['license_ids'] = licenseIds;
  axios.patch(url.toString(), data)
    .then(res => resolve(res))
    .catch(err => reject(err));
});
