/* eslint-disable no-shadow */
import Vue from 'vue';

import {
  ORGANISATION_REQUEST,
  ORGANISATION_UPDATE,
  SET_CURRENT_ORGANISATION,
  ORGANISATION_UPDATE_SUCCESS,
  GET_ORGANISATION_AS_ADMIN,
  ADMIN_ORGANISATIONS_GET,
  SET_ORGANISATIONS,
  ADMIN_ORGANISATION_PUT,
  ADMIN_ORGANISATION_DELETE,
  ADMIN_ORGANISATION_UPDATE,
} from '../actions/organisation';
import {
  apiGetOrganisation,
  apiUpdateOrganisation,
  adminApiGetOrganisation,
  adminApiGetOrganisations,
  adminApiPutOrganisation,
  adminApiDeleteOrganisation,
  adminApiPostOrganisation,
} from '../../api/organisation';

const state = {
  organisation: null,
  organisations: [],
};

const getters = {
  getOrganisation: (state) => state.organisation,

  getOrganisationById: (state) => (organisationId) => {
    let currentOrg;

    state.organisations.forEach((c) => {
      if (organisationId === c.organisation_id) {
        currentOrg = c;
      }
    });

    return currentOrg;
  },
};

const actions = {
  [ORGANISATION_REQUEST]: async ({ commit }) => {
    try {
      const response = await apiGetOrganisation();

      commit(SET_CURRENT_ORGANISATION, response.data);
      return true;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [ORGANISATION_UPDATE]: async ({ commit }, organisation) => {
    commit(ORGANISATION_UPDATE);

    try {
      const response = await apiUpdateOrganisation(organisation);

      commit(SET_CURRENT_ORGANISATION, response.data);
      return true;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [GET_ORGANISATION_AS_ADMIN]: async ({ commit }, organisation) => {
    try {
      const response = await adminApiGetOrganisation(organisation);

      commit(SET_CURRENT_ORGANISATION, response.data);
      return true;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [ADMIN_ORGANISATIONS_GET]: async ({ commit, getters }, organisationId) => {
    try {
      const response = await adminApiGetOrganisations();
      commit(SET_ORGANISATIONS, response.data);

      if (organisationId) {
        const organisation = getters.getOrganisationById(organisationId);
        commit(SET_CURRENT_ORGANISATION, organisation, false);
      }

      return true;
    } catch (error) {
      console.log('error: ', error);
      return false;
    }
  },

  [ADMIN_ORGANISATION_PUT]: async ({ commit }, orgData) => {
    try {
      const response = await adminApiPutOrganisation(orgData);

      return [true, null];
    } catch (error) {
      console.log('error: ', error);
      return [false, error];
    }
  },

  [ADMIN_ORGANISATION_DELETE]: ({ commit }, orgId) => {
    return adminApiDeleteOrganisation(orgId);
  },

  [ADMIN_ORGANISATION_UPDATE]: async ({ commit }, { orgId, orgData }) => {
    try {
      const response = await adminApiPostOrganisation(orgId, orgData);

      return [true, null];
    } catch (error) {
      console.log('error: ', error);
      return [false, error];
    }
  },
};

const mutations = {
  [SET_CURRENT_ORGANISATION]: (state, organisation, saveName = true) => {
    Vue.set(state, 'organisation', organisation);

    if (organisation && saveName) {
      localStorage['user-organisation-name'] = organisation?.name;
    }
  },
  [ORGANISATION_UPDATE_SUCCESS]: (state, resp) => {
    const orgData = resp.data;
    Vue.set(state, 'organisation', orgData);
  },
  [SET_ORGANISATIONS]: (state, data) => {
    Vue.set(state, 'organisations', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
