import { getRBGAColor } from '@/helpers/utils';
import { ButtonWidgetObject } from '@/types/widgets/buttonWidget/buttonWidget';

export function buttonStyle(button: ButtonWidgetObject, zIndex: number) {
  const embosseStyle = 'linear-gradient(#fff6 5%, #0003 100%)';
  const embosseTextStyle = '1px 1px 3px #666666';
  const borderStyle = `${button.borderWidth}px solid ${getRBGAColor(button.borderColor)}`;
  const shadowStyle = `${button.shadowBlur / 2}px ${button.shadowBlur / 2}px ${
    button.shadowBlur
  }px 0 ${getRBGAColor(button.shadowColor, button.shadowOpacity / 100)}`;

  return {
    backgroundColor: getRBGAColor(button.backgroundColor),
    border: button.displayBorder ? borderStyle : 'none',
    borderRadius: `${button.borderRadius}px`,
    boxShadow: button.displayShadow ? shadowStyle : 'none',
    color: getRBGAColor(button.textColor),
    fontFamily: button.textFont,
    fontWeight: button.fontWeight,
    fontSize: `${button.textSize}px`,
    overflow: 'hidden',
    textShadow: button.embossed ? embosseTextStyle : 'none',
    backgroundImage: button.embossed ? embosseStyle : 'none',
    zIndex: 100 + zIndex,
  };
}
