<template>
  <div :style="style">
    <video
      class="widget-video"
      :style="videoStyle"
      loop
      :muted="widget.object.mute"
      :ref="`widget_video_${widget.position.data.wid}`"
      @click="toggleVideo()"
    >
      <source :src="videoUrl" :type="widget.object.item_type" />
    </video>
  </div>
</template>

<script>
  import playerMixins from '@/models/player';

  import { simpleTypeMixin } from '@/helpers';
  import VideoSlide from '@/components/player/layerContent/VideoSlide.vue';

  export default {
    components: { VideoSlide },

    name: 'VideoWidget',

    mixins: [playerMixins, simpleTypeMixin],

    props: {
      widget: {
        type: Object,
        default: () => ({}),
      },
    },

    mounted() {
      this.playVideo();
    },

    computed: {
      style() {
        const style = this.defaultStyling(this.widget);

        return {
          ...style,
          position: 'absolute',
        };
      },

      videoUrl() {
        return this.getEncodedURL(
          this.baseUrl,
          this.widget.object.item_url || this.widget.position.data.item_url,
        );
      },

      layoutRotation() {
        return this.$store.getters.getPlayerRotation;
      },

      videoStyle() {
        return {
          objectFit: this.widget.position.data.imageMode || 'cover',
        };
      },
    },

    methods: {
      playVideo() {
        this.$refs[`widget_video_${this.widget.position.data.wid}`].currentTime = 0;
        this.$refs[`widget_video_${this.widget.position.data.wid}`].play();
      },

      toggleVideo() {
        if (this.isPlaying) {
          this.playVideo();
        } else {
          this.pauseVideo();
        }
      },

      pauseVideo() {
        this.$refs[`widget_video_${this.widget.position.data.wid}`].pause();
      },

      showImage() {
        //this.$emit('show-enlarged', this.widget)
      },
    },
  };
</script>

<style lang="scss" scoped>
  .widget-video {
    width: 100%;
    height: 100%;
  }
</style>
