import axios from 'axios';

import config from '@/config';

axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token');

export function apiGetTile(tileId) {
  const endpoint = `${config.apiUrl}userapi/local-templates/${tileId}`;
  return axios.get(endpoint);
}

export function apiGetGroupTile(tileId) {
  const endpoint = `${config.apiUrl}userapi/local-template-overrides/screen-group/${tileId}`;
  return axios.get(endpoint);
}

export function apiGetScreenTile(tileId) {
  const endpoint = `${config.apiUrl}userapi/local-template-overrides/${tileId}`;
  return axios.get(endpoint);
}

export function apiCreateTile({ templateId, playlistId, tileOverrideIds = [], position = 100 }) {
  const endpoint = `${config.apiUrl}userapi/local-templates`;

  return axios.post(endpoint, {
    template_id: templateId,
    playlist_id: playlistId,
    template_override_ids: tileOverrideIds,
    playlist_item_priority: position,
  });
}

export function apiCloneLocalTemplateOnPlaylists(
  localTemplateId,
  playlistId,
  localTemplateOverrideIds = [],
  playlistPosition,
  screenId,
  groupId,
) {
  const endpoint = `${config.apiUrl}userapi/local-templates/clone`;

  return axios.post(endpoint, {
    template_id: localTemplateId,
    playlist_id: playlistId,
    template_override_ids: localTemplateOverrideIds,
    playlist_item_priority: playlistPosition,
    screenId,
    groupId,
  });
}

export function apiDeleteTile(tileId) {
  const endpoint = `${config.apiUrl}userapi/local-templates/${tileId}`;
  return axios.delete(endpoint);
}

export function apiUpdateTile(tileId, settings) {
  const endpoint = `${config.apiUrl}userapi/local-templates/${tileId}`;
  return axios.patch(endpoint, {
    name: settings.name,
    settings,
  });
}

export function apiReplaceTileChildItem(tileId, widgetData) {
  const endpoint = `${config.apiUrl}userapi/local-templates/${tileId}/children/replace`;
  return axios.put(endpoint, {
    ...widgetData,
  });
}

export function apiCreateTemplateFromTile({
  name,
  tileId,
  overrideIds = [],
  thumbnail,
  screenId,
  groupId,
}) {
  const endpoint = `${config.apiUrl}userapi/copy-local-template`;

  return axios.post(endpoint, {
    name,
    local_template_id: tileId,
    template_override_ids: overrideIds,
    thumbnail,
    screenId,
    groupId,
  });
}

// ---- TILE CHILDREN ---- //

export function apiGetTileChildren(tileId) {
  const endpoint = `${config.apiUrl}userapi/local-templates/${tileId}/children`;
  return axios.get(endpoint);
}

export function apiAttachTileChild(tileId, widgetData) {
  const endpoint = `${config.apiUrl}userapi/local-templates/${tileId}/children`;
  return axios.post(endpoint, widgetData);
}

export function apiUpdateTileChild(tileId, widgetData) {
  const endpoint = `${config.apiUrl}userapi/local-templates/${tileId}/children`;
  return axios.put(endpoint, widgetData);
}

export function apiRemoveTileChild(tileId, data) {
  const endpoint = `${config.apiUrl}userapi/local-templates/${tileId}/children`;
  return axios.delete(endpoint, {
    data,
  });
}

// ---- Tile OVERRIDES ---- //

export function apiCreateTileAddOverride(payload) {
  const url = `${config.apiUrl}userapi/local-template-playlist-override`;

  return axios.post(url, payload);
}

export function apiCreateTileFromTileAddOverride(payload) {
  const url = `${config.apiUrl}userapi/local-template-from-local-template-playlist-override`;

  return axios.post(url, payload);
}

export function apiGetScreenTileChildren(tileId, screenId, playlistId) {
  const url = `${config.apiUrl}userapi/screens/${screenId}/local-template/${tileId}/children?playlist_id=${playlistId}`;

  return axios.get(url);
}

export function apiGetGroupTileChildren(tileId, groupId, playlistId, layoutId) {
  const url = `${config.apiUrl}userapi/screen-group/${groupId}/local-template/${tileId}/children?playlist_id=${playlistId}&layout=${layoutId}`;

  return axios.get(url);
}

export function apiCreateTileOverride(payload) {
  return axios.post(`${config.apiUrl}userapi/template-overrides`, payload);
}

export function apiCreateScreenTileItemOverride({
  action,
  screenId,
  itemId,
  newItemId,
  tileId,
  overrideConfig = {},
  playlistId,
  position,
}) {
  return axios.post(`${config.apiUrl}userapi/local-template-overrides`, {
    action,
    screen: screenId,
    original_item: itemId,
    template: tileId,
    local_item: newItemId,
    config: overrideConfig,
    playlist: playlistId,
    position,
  });
}

export function apiCreateGroupTileItemOverride({
  action,
  groupId,
  itemId,
  newItemId,
  tileId,
  overrideConfig = {},
  playlistId,
  position,
  layout,
}) {
  return axios.post(`${config.apiUrl}userapi/local-template-overrides/screen-group`, {
    action,
    screen_group: groupId,
    original_item: itemId,
    local_item: newItemId,
    template: tileId,
    config: overrideConfig,
    playlist: playlistId,
    position,
    layout,
  });
}

export function apiScreenUpdateTileItemOverride(
  { action, overrideId, itemId, newItemId, tileId, position, overrideConfig = {} },
  playlistId,
) {
  const payload = {
    action,
    override_id: overrideId,
    original_item: itemId,
    template: tileId,
    config: overrideConfig,
    local_item: newItemId,
    playlist: playlistId,
    position,
  };

  return axios.put(`${config.apiUrl}userapi/local-template-overrides`, payload);
}

export function apiGroupUpdateTileItemOverride({
  action,
  overrideId,
  itemId,
  newItemId,
  tileId,
  overrideConfig = {},
  playlistId,
  position,
}) {
  const payload = {
    action,
    override_id: overrideId,
    original_item: itemId,
    template: tileId,
    config: overrideConfig,
    local_item: newItemId,
    playlist: playlistId,
    position,
  };

  return axios.put(`${config.apiUrl}userapi/local-template-overrides/screen-group`, payload);
}

export function apiScreenDeleteTileItemOverride(screenId, templateId, overrideId) {
  return axios.delete(
    `${config.apiUrl}userapi/local-template-overrides/${screenId}?override_id=${overrideId}&template_id=${templateId}`,
  );
}

export function apiGroupDeleteTileItemOverride(groupId, templateId, overrideId) {
  return axios.delete(
    `${config.apiUrl}userapi/local-template-overrides/screen-group/${groupId}?override_id=${overrideId}&template_id=${templateId}`,
  );
}

export function apiChangeOverrideToDelete(overrideId) {
  return axios.delete(`${config.apiUrl}userapi/local-template-override/${overrideId}`);
}
