import { 
  EVENTLOG_REQUEST,
  EVENTLOG_ERROR,
  EVENTLOG_SUCCESS
} from '../actions/eventlog'
import {apiGetEventlog} from '../../api/eventlog'
import Vue from 'vue'

const state = { 
  eventlog: [],
  eventlogRequestStatus: '',
  eventlogRequestError: '',
}

const getters = {
  getEventlog: state => state.eventlog,
  eventlogStatus: state => state.status
}

const actions = {
  [EVENTLOG_REQUEST]: ({commit, dispatch}) => {
    commit(EVENTLOG_REQUEST)
    var p = apiGetEventlog()
    p.then((resp) => {
      commit(EVENTLOG_SUCCESS, resp)
    }).catch((error) => {
      commit(EVENTLOG_ERROR, error)
    })
    return p
  }

}

const mutations = {
  [EVENTLOG_REQUEST]: (state) => {
    state.eventlogRequestStatus = 'loading'
  },
  [EVENTLOG_SUCCESS]: (state, resp) => {
    Vue.set(state, 'eventlog', resp.data)
    state.eventlogRequestStatus = 'success'
  },
  [EVENTLOG_ERROR]: (state, error) => {
    state.eventlogRequestError = error.response.data.message
    state.eventlogRequestStatus = 'error'
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
