import axios from 'axios';
import config from '../config';

axios.defaults.headers.common.Authorization = localStorage.getItem('user-session_token');

export const apiGetRootContent = () =>
  new Promise((resolve, reject) => {
    const apiUrl = `${config.apiUrl}userapi/contents/folders?remove_old_apps=true`;

    axios
      .get(apiUrl)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiUploadFile = (name, file = null, folder) =>
  new Promise((resolve, reject) => {
    const apiUrl = `${config.apiUrl}userapi/contents/items`;

    const formData = new FormData();
    formData.append('name', name);

    if (file) {
      formData.append('upload', file);
    }

    if (folder) {
      formData.append('folder', folder);
    }

    const axiosConfig = { headers: { 'content-type': 'multipart/form-data' } };

    axios
      .post(apiUrl, formData, axiosConfig)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiDeleteFile = (fileId) =>
  new Promise((resolve, reject) => {
    const apiUrl = `${config.apiUrl}userapi/contents/items?item_id=${fileId}`;

    axios
      .delete(apiUrl)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiDeleteFiles = (fileIds) =>
  new Promise((resolve, reject) => {
    let idsPath = '';

    fileIds.forEach((fileId) => {
      idsPath += `&item_id=${fileId}`;
    });

    const apiUrl = `${config.apiUrl}userapi/contents/items?${idsPath}`;

    axios
      .delete(apiUrl)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiUploadPDF = (file, images, folder) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('pdfFile', file);
    images.forEach((image) => {
      formData.append('thumbs', image);
    });

    if (folder) formData.append('folder', folder);

    const axiosConfig = { headers: { 'Content-Type': 'multipart/form-data' } };

    axios
      .post(`${config.apiUrl}userapi/pdf`, formData, axiosConfig)
      .then((resp) => resolve(resp.data))
      .catch((error) => {
        reject(error);
      });
  });

export const apiDeletePdf = (pdfId) => {
  const apiUrl = `${config.apiUrl}userapi/media?prefix=pdf_file&app_id=${pdfId}`;

  return axios.delete(apiUrl);
};

export const apiMoveFile = (itemId, folderId) => {
  const apiUrl = `${config.apiUrl}userapi/contents/items/${itemId}`;
  return new Promise((resolve, reject) => {
    axios
      .put(apiUrl, { folder: folderId })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
};

export const apiMultiMoveFiles = async (fileIds, folderId) => {
  const apiUrl = `${config.apiUrl}userapi/contents/items`;
  return axios.patch(apiUrl, { folder: folderId, item_ids: fileIds });
};

export const apiCreateFolder = (body) => {
  const apiUrl = `${config.apiUrl}userapi/contents/folders`;

  return axios.post(apiUrl, body);
};

export const apiDeleteFolder = (folderId) => {
  const apiUrl = `${config.apiUrl}userapi/contents/folders/${folderId}`;

  return axios.delete(apiUrl);
};

export const apiGetContentFolder = (folderId) => {
  const endpoint = `${config.apiUrl}userapi/contents/folders/${folderId}`;
  return axios.get(endpoint);
};

export const apiUpdateContentFolder = ({ folderId, name }) => {
  const endpoint = `${config.apiUrl}userapi/contents/folders/${folderId}`;
  return new Promise((resolve, reject) => {
    axios
      .put(endpoint, {
        name,
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
};
