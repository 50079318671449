export function isFunction(func) {
  return typeof func === 'function' || Object.prototype.toString.call(func) === '[object Function]';
}

export function getSize(el) {
  const rect = el.getBoundingClientRect();

  return [parseInt(rect.width), parseInt(rect.height)];
}

export function computeWidth(parentWidth, left, right) {
  return parentWidth - left - right;
}

export function computeHeight(parentHeight, top, bottom) {
  return parentHeight - top - bottom;
}

export function restrictToBounds(value, min, max) {
  if (min !== null && value < min) {
    return min;
  }

  if (max !== null && max < value) {
    return max;
  }

  return value;
}

export function matchesSelectorToParentElements(el, selector, baseNode) {
  let node = el;

  const matchesSelectorFunc = [
    'matches',
    'webkitMatchesSelector',
    'mozMatchesSelector',
    'msMatchesSelector',
    'oMatchesSelector',
  ].find((func) => isFunction(node[func]));

  if (!isFunction(node[matchesSelectorFunc])) return false;

  do {
    if (node[matchesSelectorFunc](selector)) return true;
    if (node === baseNode) return false;
    node = node.parentNode;
  } while (node);

  return false;
}

export function getComputedSize($el) {
  const style = window.getComputedStyle($el);

  return [
    parseFloat(style.getPropertyValue('width'), 10),
    parseFloat(style.getPropertyValue('height'), 10),
  ];
}

export function addEvent(el, event, handler) {
  if (!el) {
    return;
  }
  if (el.attachEvent) {
    el.attachEvent('on' + event, handler);
  } else if (el.addEventListener) {
    el.addEventListener(event, handler, true);
  } else {
    el['on' + event] = handler;
  }
}

export function removeEvent(el, event, handler) {
  if (!el) {
    return;
  }
  if (el.detachEvent) {
    el.detachEvent('on' + event, handler);
  } else if (el.removeEventListener) {
    el.removeEventListener(event, handler, true);
  } else {
    el['on' + event] = null;
  }
}
