var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget-item",style:(_vm.style)},[_c('PlaylistPlayer',{style:({
      position: 'relative',
    }),attrs:{"playlist":{
      ..._vm.widget.object,
      imageMode: _vm.widget.object.imageMode || _vm.widget.position.imageMode,
      playlistId: _vm.widget.playlist_id,
      wid: _vm.widget.object.wid,
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }