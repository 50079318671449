import config from '../config';
import axios from 'axios';

axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token');

export const getApiOrganisationRoles = async (organisationId, name = '') => {
  let apiUrl = `${config.apiUrl}adminapi/roles/organisation/${organisationId}?name=${name}`;

  return await axios.get(apiUrl);
};

export const getRoles = (name = '') => {
  const url = new URL('userapi/roles', config.apiUrl);

  if(name) {
    url.searchParams.append(name, name);
  }
  return new Promise((resolve, reject) => {
    axios.get(url.toString())
      .then(res => resolve(res))
      .catch(err => reject(err.response));
  });
}

export const createLocalRole = (name) => {
  const url = new URL('userapi/roles', config.apiUrl);
  return new Promise((resolve, reject) => {
    axios.post(url.toString(), {name})
      .then(res => resolve(res.data))
      .catch(err => reject(err.response));
  });
}

export const updateLocalRole = (roleId, name) => {
  const url = new URL(`userapi/roles/${roleId}`, config.apiUrl);
  return new Promise((resolve, reject) => {
    axios.put(url.toString(), {name})
      .then(res => resolve(res.data))
      .catch(err => reject(err.response));
  });
}

export const deleteLocalRole = (roleId) => {
  const url = new URL(`userapi/roles/${roleId}`, config.apiUrl);
  return new Promise((resolve, reject) => {
    axios.delete(url.toString())
      .then(res => resolve(res.data))
      .catch(err => reject(err.response));
  });
}

export const createRoleApi = async (organisationId, roleData) => {
  let apiUrl = `${config.apiUrl}adminapi/roles/organisation/${organisationId}`;

  return await axios.post(apiUrl, roleData);
};

export const updateRoleApi = async (roleId, roleData) => {
  let apiUrl = `${config.apiUrl}adminapi/roles/${roleId}`;

  return await axios.put(apiUrl, roleData);
};

export const deleteRoleApi = async (roleId) => {
  let apiUrl = `${config.apiUrl}adminapi/roles/${roleId}`;

  return await axios.delete(apiUrl);
};
