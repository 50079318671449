import config from '../config'
import axios from 'axios'

axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token')

export const apiGetRss = (rss_url) => new Promise((resolve, reject) => {
    var apiUrl = `${config.apiUrl}userapi/app/demo/rss?url=${rss_url}`;

    var p = axios.get(apiUrl).then((resp) => {
        resolve(resp)
    }).catch((error) => {
        reject(error)
    })
    return p
})
