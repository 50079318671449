import { DASHBOARD_STATISTICS_REQUEST } from '../actions/dashboard';
import { apiGetDashboardStatistics } from '@/api/dashboard';
import { DashboardStatistics } from '@/types/api/dashboard';
import { StoreStatus } from '@/types/store';
import { AxiosError } from 'axios';

export interface DashboardState {
  dashboardStatistics: {
    data: DashboardStatistics;
    error: any;
    status: StoreStatus | null;
  };
}

const initialState: DashboardState = {
  dashboardStatistics: {
    data: {
      users: 0,
      connectedScreens: 0,
      idleOrDisconnectedScreens: 0,
      notConnectScreens: 0,
      totalScreens: 0,
      licenses: 0,
      activeLicenses: 0,
      expiredLicenses: 0,
      expiringLicenses: 0,
      totalLayouts: 0,
      liveLayouts: 0,
    },
    error: null,
    status: null,
  },
};

export default {
  state: initialState,
  mutations: {
    setDashboardStatistics(state, dashboardStatistics: DashboardStatistics) {
      state.dashboardStatistics = dashboardStatistics;
    },
  },
  actions: {
    [DASHBOARD_STATISTICS_REQUEST]: async ({ commit, state }) => {
      commit('setDashboardStatistics', {
        ...state.dashboardStatistics,
        error: null,
        status: StoreStatus.IsLoading,
      });

      try {
        const response = await apiGetDashboardStatistics();

        commit('setDashboardStatistics', {
          ...state.dashboardStatistics,
          data: response?.data,
          error: null,
          status: StoreStatus.IsSuccess,
        });
      } catch (err) {
        const error = err as AxiosError;

        commit('setDashboardStatistics', {
          ...state.dashboardStatistics,
          error: error?.response,
          status: StoreStatus.IsError,
        });

        console.error('Failed to get dashboard statistics', JSON.stringify(error));
      }
    },
  },
  getters: {
    getDashboardStatistics(state) {
      return state.dashboardStatistics;
    },
  },
};
