import Vue from 'vue';
import {
  CONTENT_DELETE_PDF_REQUEST,
  CONTENT_REQUEST,
  CONTENT_REQUEST_ERROR,
  CONTENT_REQUEST_SUCCESS,
  CONTENT_UPLOAD_PDF_REQUEST,
  FILE_UPLOAD_REQUEST,
  DELETE_CONTENT_REQUEST,
  FOLDER_SEARCH_REQUEST,
  FOLDER_FILTER_REQUEST,
} from '@/store/actions/content';

import {
  CONTENT_TYPE_FOLDER,
  CONTENT_TYPE_RSS_APP,
  CONTENT_TYPE_TABLE_APP,
  CONTENT_TYPE_TRAFIKLAB_APP,
  CONTENT_TYPE_WEATHER_APP,
} from '@/constant/const';

import {
  apiDeleteContent,
  apiDeletePdf,
  apiGetRootContent,
  apiUploadFile,
  apiUploadPDF,
} from '@/api/content';

import config from '@/config';
import { getEncodedURL } from '@/helpers/mixins';
import { getFoldersFromNestedArray } from '@/helpers/utils';

const initialState = {
  requestStatus: '',
  requestError: '',
  content: [],
  rootId: [],

  folderSearch: '',
  folderFilter: '',

  updateStatus: '',
  updateError: '',

  deleteStatus: '',
  deleteError: '',
};

const getters = {
  getFolders: (state) => {
    return state.content
      .filter((content) => content.content_type === CONTENT_TYPE_FOLDER)
      .map((folder) => ({
        id: folder.id,
        name: folder.name,
        path: folder.full_path,
      }));
  },

  getFoldersDetails: (state) =>
    state.content.filter((content) => {
      let validFolder = content.content_type === CONTENT_TYPE_FOLDER;

      if (validFolder && state.folderSearch) {
        validFolder = content?.name?.toLowerCase()?.includes(state.folderSearch);
      }
      if (validFolder && state.folderFilter) {
        validFolder = folderHasContentType(content, state.folderFilter);
      }
      return validFolder;
    }),

  getAllContentFolders: (state) => {
    return getFoldersFromNestedArray(state.content);
  },
};

const actions = {
  [CONTENT_REQUEST]: async ({ commit }) => {
    commit(CONTENT_REQUEST);

    try {
      const response = await apiGetRootContent();

      commit(CONTENT_REQUEST_SUCCESS, response);
    } catch (error) {
      commit(CONTENT_REQUEST_ERROR, error);
    }
  },

  [FILE_UPLOAD_REQUEST]: async (_, { name, file, folder }) => {
    try {
      const response = await apiUploadFile(name, file, folder);

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  [CONTENT_UPLOAD_PDF_REQUEST]: (_, { file, images, folder }) => {
    const p = apiUploadPDF(file, images, folder);
    p.then((resp) => {
      console.log(resp.data);
    }).catch((error) => {
      console.log(error);
    });
    return p;
  },

  [DELETE_CONTENT_REQUEST]: (_, name) => {
    const p = apiDeleteContent(name);
    return p;
  },

  [CONTENT_DELETE_PDF_REQUEST]: (_, pdfId) => {
    const p = apiDeletePdf(pdfId);
    return p;
  },
  [FOLDER_SEARCH_REQUEST]: ({ commit }, folderName) => {
    commit(FOLDER_SEARCH_REQUEST, folderName);
  },
  [FOLDER_FILTER_REQUEST]: ({ commit }, contentType) => {
    commit(FOLDER_FILTER_REQUEST, contentType);
  },
};

const mutations = {
  [CONTENT_REQUEST]: () => {
    initialState.requestStatus = 'loading';
  },
  [CONTENT_REQUEST_SUCCESS]: (_, resp) => {
    // eslint-disable-next-line no-use-before-define
    const content = processContentData(resp.data?.contents);
    Vue.set(initialState, 'content', content);
    Vue.set(initialState, 'rootId', resp.data?.id);
    initialState.requestStatus = 'success';
  },
  [CONTENT_REQUEST_ERROR]: (_, error) => {
    initialState.requestError = String(error);
    initialState.requestStatus = 'error';
  },
  [FOLDER_SEARCH_REQUEST]: (_, folderName = '') => {
    initialState.folderSearch = folderName.toLowerCase();
  },
  [FOLDER_FILTER_REQUEST]: (_, contentType) => {
    initialState.folderFilter = contentType;
  },
};

export default {
  state: initialState,
  getters,
  actions,
  mutations,
};

const trafiklabAppImg = require('@/assets/img/apps/trafiklab.png');
const folderImg = require('@/assets/img/content/folder.png');
const rssAppImg = require('@/assets/img/apps/rss.png');
const tableAppImg = require('@/assets/img/TableApp.png');
const weatherAppImg = require('@/assets/img/apps/weather.png');

const processContentData = function (data) {
  const { baseUrl } = config;

  return data
    .map((el) => {
      const element = el;
      if (el.content_type === CONTENT_TYPE_FOLDER) {
        element.img = folderImg;
        element.contents = processContentData(element.contents);
      } else if (el.item_type?.includes('image')) {
        element.img = getEncodedURL(baseUrl, el.item_url);
      } else if (el.item_type?.includes('video')) {
        element.img = getEncodedURL(baseUrl, el.thumbnail_url);
      } else if (el.item_type?.includes('pdf') && el.pdf_content) {
        element.img = el.pdf_content.thumbnail_url[0];
      } else if (el.item_type === CONTENT_TYPE_RSS_APP) {
        element.img = rssAppImg;
      } else if (el.item_type === CONTENT_TYPE_TABLE_APP) {
        element.img = tableAppImg;
      } else if (el.item_type === CONTENT_TYPE_TRAFIKLAB_APP) {
        element.img = trafiklabAppImg;
      } else if (el.item_type === CONTENT_TYPE_WEATHER_APP) {
        element.img = weatherAppImg;
      }

      return element;
    })
    .filter((item) => !!item.item_type || !!item.content_type);
};

function folderHasContentType(folder, contentType) {
  if (folder?.contents?.length) {
    return folder.contents.some((content) => {
      if (content.content_type === CONTENT_TYPE_FOLDER) {
        return folderHasContentType(content, contentType);
      }
      if (contentType === 'app') {
        return content?.item_type?.startsWith('app/');
      }
      if (contentType === 'image') {
        return content.item_type.startsWith('image');
      }
      if (contentType === 'video') {
        return content.item_type.startsWith('video');
      }
      if (contentType === 'pdf_file') {
        return content.item_type === 'app/pdf';
      }
      return content.item_type === contentType;
    });
  }
  return false;
}
