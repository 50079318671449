<template>
  <div class="widget-item" :id="widget.object.wid" :style="style">
    <TemplateViewer ref="TemplateViewer" :template="widget.object" />
  </div>
</template>

<script>
  import playerMixins from '@/models/player';
  import TemplateViewer from '@/components/templates/viewer/TemplateViewer.vue';
  import { simpleTypeMixin } from '@/helpers';

  export default {
    name: 'TemplateWidget',

    components: { TemplateViewer },

    mixins: [simpleTypeMixin, playerMixins],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    computed: {
      style() {
        const style = this.defaultStyling(this.widget);

        return {
          ...style,
          position: 'absolute',
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .widget-item {
    position: absolute;
    overflow: hidden;
  }
</style>
