export const RESET_PLAYER = 'RESET_PLAYER';
export const PLAYER_LOAD_LAYOUT = 'PLAYER_LOAD_LAYOUT';
export const PLAYER_LOAD_LAYOUT_SUCCESS = 'PLAYER_LOAD_LAYOUT_SUCCESS';
export const PLAYER_LOAD_LAYOUT_ERROR = 'PLAYER_LOAD_LAYOUT_FAILURE';

export const PLAYER_LOAD_PLAYLISTS = 'PLAYER_LOAD_PLAYLISTS';
export const PLAYER_LOAD_PLAYLISTS_SUCCESS = 'PLAYER_LOAD_PLAYLISTS_SUCCESS';
export const PLAYER_LOAD_PLAYLISTS_ERROR = 'PLAYER_LOAD_PLAYLISTS_FAILURE';

export const PLAYER_LOAD_LAYOUT_CHILDREN = 'PLAYER_LOAD_LAYOUT_CHILDREN';
export const PLAYER_SET_LAYOUT = 'PLAYER_SET_LAYOUT';
export const SET_LAYOUT_CHILDREN = 'SET_LAYOUT_CHILDREN';

export const SET_PLAYER_DEMO = 'SET_PLAYER_DEMO';
export const SET_SCREEN_SIZE = 'SET_SCREEN_SIZE';

export const SET_SELECTED_WIDGET = 'SET_SELECTED_WIDGET';

export const SET_PLAYLIST_UPDATE_STATE = 'SET_PLAYLIST_UPDATE_STATE';

export const OPEN_PLAYER_MODAL = 'OPEN_PLAYER_MODAL';
export const CLOSE_PLAYER_MODAL = 'CLOSE_PLAYER_MODAL';
export const ALLOW_PLAYER_MODAL = 'ALLOW_PLAYER_MODAL';
