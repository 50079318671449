<template>
  <div class="widget-item clock-widget" :id="widget.object.wid" :style="style">
    <div class="clock-widget-clock-wrapper" v-if="!loading" :style="clockStyle">
      <div class="current-time-string auto-fit-text">
        <span>{{ showTime() }}</span>
      </div>

      <div v-if="widget.object.showDate" class="current-date-string auto-fit-text">
        <span>{{ showDate() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import playerMixins from '@/models/player';
  import { simpleTypeMixin } from '@/helpers';
  const moment = require('moment-timezone');

  import config from '@/config';
  import { getRBGAColor } from '@/helpers/utils';

  const CLOCK_TIME = 'HH:mm';
  const CLOCK_DATE = 'MMM D';

  export default {
    name: 'ClockWidget',
    mixins: [playerMixins, simpleTypeMixin],
    props: {
      widget: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        loading: true,
        clockObj: null,
        timer: null,
      };
    },

    computed: {
      style() {
        const style = this.defaultStyling(this.widget);

        return {
          ...style,
          overflow: 'hidden',
          fontSize: `${this.widget.object.textSize}px`,
        };
      },

      clockStyle() {
        return {
          backgroundColor: getRBGAColor(
            this.widget.object.backgroundColor,
            Number(this.widget.object.backgroundOpacity) / 100,
          ),
          color: getRBGAColor(
            this.widget.object.textColor,
            Number(this.widget.object.opacity) / 100,
          ),
          textAlign: this.widget.object.alignment,
          backgroundPosition: 'center',
        };
      },

      layoutRotation() {
        return this.$store.getters.getPlayerRotation;
      },
    },

    methods: {
      updateClock() {
        this.clockObj = moment();
        this.clockObj.tz(config.timezone);
      },

      showDate() {
        return this.clockObj.format(CLOCK_DATE);
      },

      showTime() {
        return this.clockObj.format(CLOCK_TIME);
      },

      clockTick() {
        this.updateClock();
      },
    },

    mounted() {
      this.updateClock();
      this.loading = false;

      if (this.timer) {
        clearInterval(this.timer);
      }

      this.timer = setInterval(this.updateClock, 1000);
    },
  };
</script>

<style lang="scss" scoped>
  .clock-widget {
    position: absolute;
  }

  .clock-widget-clock-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
</style>
