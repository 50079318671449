export const CONTENT_REQUEST = 'CONTENT_REQUEST';
export const CONTENT_REQUEST_SUCCESS = 'CONTENT_REQUEST_SUCCESS';
export const CONTENT_REQUEST_ERROR = 'CONTENT_REQUEST_ERROR';

export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const CONTENT_UPLOAD_REQUEST_SUCCESS = 'CONTENT_UPLOAD_REQUEST_SUCCESS';
export const CONTENT_UPLOAD_REQUEST_ERROR = 'CONTENT_UPLOAD_REQUEST_ERROR';

export const CONTENT_UPLOAD_PDF_REQUEST = 'CONTENT_UPLOAD_PDF_REQUEST';
export const CONTENT_UPLOAD_PDF_REQUEST_SUCCESS = 'CONTENT_UPLOAD_PDF_REQUEST_SUCCESS';
export const CONTENT_UPLOAD_PDF_REQUEST_ERROR = 'CONTENT_UPLOAD_PDF_REQUEST_ERROR';

export const CONTENT_DELETE_PDF_REQUEST = 'CONTENT_DELETE_PDF_REQUEST';

export const DELETE_CONTENT_REQUEST = 'DELETE_CONTENT_REQUEST';
export const DELETE_CONTENT_REQUEST_SUCCESS = 'DELETE_CONTENT_REQUEST_SUCCESS';
export const DELETE_CONTENT_REQUEST_ERROR = 'DELETE_CONTENT_REQUEST_ERROR';
export const FOLDER_SEARCH_REQUEST = 'FOLDER_SEARCH_REQUEST';
export const FOLDER_FILTER_REQUEST = 'FOLDER_FILTER_REQUEST';
