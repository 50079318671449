import config from '../config';
import axios from 'axios';
import store from '@/store';
import { AUTH_LOGOUT } from '@/store/actions/auth';

axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token');
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch(AUTH_LOGOUT);
    }
    return Promise.reject(error);
  },
);

export const apiCreateApp = (data) =>
  new Promise((resolve, reject) => {
    var apiUrl = `${config.apiUrl}userapi/app`;

    var p = axios
      .post(apiUrl, data)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
    return p;
  });

export const apiDeleteApp = (appId) =>
  new Promise((resolve, reject) => {
    var apiUrl = `${config.apiUrl}userapi/media?app_id=${appId}`;

    var p = axios
      .delete(apiUrl)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
    return p;
  });

export const apiUpdateApp = (settings) =>
  new Promise((resolve, reject) => {
    var apiUrl = `${config.apiUrl}userapi/app/${settings.id}`;

    var p = axios
      .put(apiUrl, settings)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
    return p;
  });
