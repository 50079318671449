import config from '../config';
import axios from 'axios';

export const apiWeatherCurrentConditions = async (location, language = 'en', type, metric) => {
  const url = new URL('userapi/app/weather/get-weather', config.apiUrl);

  return new Promise((resolve, reject) => {
    axios
      .post(url.toString(), { location, language, type, metric })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
};
