<template>
  <div class="widget-item" :style="style">
    <PlaylistPlayer
      :style="{
        position: 'relative',
      }"
      :playlist="{
        ...widget.object,
        imageMode: widget.object.imageMode || widget.position.imageMode,
        playlistId: widget.playlist_id,
        wid: widget.object.wid,
      }"
    />
  </div>
</template>

<script>
  import playerMixins from '@/models/player';
  import { simpleTypeMixin } from '@/helpers';

  import PlaylistPlayer from '@/components/player/PlaylistPlayer.vue';

  export default {
    name: 'PlaylistWidget',

    mixins: [playerMixins, simpleTypeMixin],

    components: {
      PlaylistPlayer,
    },

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    computed: {
      style() {
        const style = this.defaultStyling(this.widget);

        return {
          ...style,
        };
      },

      rotation() {
        return this.$store.getters.getPlayerRotation;
      },
    },
  };
</script>

<style scoped lang="scss">
  .widget-item {
    position: absolute;
  }
</style>
