<template>
  <div :class="[defaultVariantStyle]" v-bind="$attrs">
    <slot />
  </div>
</template>

<script>
  import { TEXT_CLASS, TextVariant } from './types';

  export default {
    name: 'BaseText',
    props: {
      variant: {
        type: String,
        validator(value) {
          return Object.values(TextVariant).includes(value);
        },
        default: TextVariant.Body2,
      },
      color: {
        type: String,
        default: '#151515',
      },
    },
    computed: {
      defaultVariantStyle() {
        return this.$style[TEXT_CLASS[this.variant] || TEXT_CLASS.body2];
      },
    },
  };
</script>

<style lang="scss" scoped module>
  .text-h1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 6rem;
  }

  .text-h2 {
    font-size: 3.75rem;
    font-weight: 300;
    line-height: 3.75rem;
  }

  .text-h3 {
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.125rem;
  }

  .text-h4 {
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 2.5rem;
  }

  .text-h5 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
  }

  .text-h6 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
  }

  .text-subtitle1 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75rem;
  }

  .text-subtitle2 {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;
  }

  .text-body1 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  .text-body2 {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  .text-caption1 {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  .inputLabel {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
  }

  .text-title-1 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  .text-h1,
  .text-h2,
  .text-h3,
  .text-h4,
  .text-h5,
  .text-h6,
  .subtitle1,
  .subtitle2,
  .text-body1,
  .text-body2,
  .text-caption1 {
    color: v-bind(color);
  }
</style>
