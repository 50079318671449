import {
  SCREEN_CONTENT_CHANGED,
  SCREEN_GROUP_CONTENT_CHANGED,
} from '@/store/actions/screenContentManagment';
import { apiScreenContentChange, apiScreenGroupContentChange } from '@/api/screenContentManagement';

export interface ScreenContentManagementState {
  screen: {
    screenId?: number;
    isLoading: boolean;
    isError: boolean;
  };
  screenGroup: {
    screenGroupId?: number;
    isLoading: boolean;
    isError: boolean;
  };
}

const initialState: ScreenContentManagementState = {
  screen: {
    screenId: undefined,
    isLoading: false,
    isError: false,
  },
  screenGroup: {
    screenGroupId: undefined,
    isLoading: false,
    isError: false,
  },
};

export default {
  state: initialState,
  mutations: {},
  actions: {
    [SCREEN_CONTENT_CHANGED]: async ({ state }, screenId: number) => {
      state.screen.isLoading = true;
      state.screen.isError = false;

      try {
        await apiScreenContentChange(screenId);

        state.screen.screenId = screenId;
      } catch (error) {
        console.error('Failed to notify screen content has changed', error);
        state.screen.isError = true;
      }

      state.screen.isLoading = false;
    },
    [SCREEN_GROUP_CONTENT_CHANGED]: async (
      { state },
      {
        screenGroupId,
        layoutId,
        screenIds,
      }: { screenGroupId: number; layoutId: string; screenIds?: number[] },
    ) => {
      state.screenGroup.isLoading = true;
      state.screenGroup.isError = false;

      try {
        await apiScreenGroupContentChange(screenGroupId, layoutId, screenIds);

        state.screenGroup.screenGroupId = screenGroupId;
      } catch (error) {
        console.error('Failed to notify screen group content has changed', error);
        state.screenGroup.isError = true;
      }

      state.screenGroup.isLoading = false;
    },
  },
  getters: {},
};
