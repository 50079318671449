import axios from 'axios';
import config from '../config';

const apiLogin = ({ username, password }) =>
  new Promise((resolve, reject) => {
    const apiUrl = `${config.apiUrl}auth/login`;
    axios
      .post(apiUrl, {
        username,
        password,
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiLoginAsUser = (userId, superAdminToken) =>
  new Promise((resolve, reject) => {
    const apiUrl = `${config.apiUrl}adminapi/switch-account/users/${userId}`;

    axios
      .post(apiUrl, {
        headers: {
          Authorization: superAdminToken,
        },
      })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiLoginAsUserExit = (userToken) =>
  new Promise((resolve, reject) => {
    const apiUrl = `${config.apiUrl}auth/logout`;

    const p = axios
      .post(
        apiUrl,
        {},
        {
          headers: {
            Authorization: userToken,
          },
        },
      )
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiGetLoginAsUser = (userId, token) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}userapi/user/${userId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiForgotPassword = (username, resetPasswordUrl) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${config.apiUrl}userapi/forget-password`;
    return axios
      .post(apiUrl, {
        email: username,
        reset_password_page_url: resetPasswordUrl,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const apiResetPassword = (token, newPassword) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${config.apiUrl}userapi/reset-forgotten-password`;
    return axios
      .post(apiUrl, {
        token,
        new_password: newPassword,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export default apiLogin;
