import {
  GET_ORGANISATION_ROLES,
  SET_ORGANISATION_ROLES,
  CREATE_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE
} from '@/store/actions/roles';

import {
  getApiOrganisationRoles,
  createRoleApi,
  updateRoleApi,
  deleteRoleApi,
  getRoles, createLocalRole, updateLocalRole, deleteLocalRole
} from '@/api/roles';

const state = {
  roles: [],
};

const getters = {};

const actions = {
  [GET_ORGANISATION_ROLES]: async ({ commit, rootState, getters }, name = '') => {
    try {
      let response;

      if(getters.isAdministrator) {
        const organisationId = rootState.organisation.organisation.organisation_id;
        response = await getApiOrganisationRoles(organisationId, name);
      } else {
        response = await getRoles(name);
      }
      commit(SET_ORGANISATION_ROLES, response.data);
      return true;
    } catch (error) {
      return false;
    }
  },

  [CREATE_ROLE]: async ({ rootState, getters }, roleName) => {
    try {
      const data = {
        name: roleName,
      };
      if(getters.isAdministrator) {
        const organisationId = rootState.organisation.organisation.organisation_id;
        await createRoleApi(organisationId, data)
      } else {
        await createLocalRole(roleName);
      }
      return true;
    } catch {
      return false;
    }
  },

  [UPDATE_ROLE]: async ({ getters }, role) => {
    try {
      getters.isAdministrator
        ? await updateRoleApi(role.id, role)
        : await updateLocalRole(role.id, role.name);

      return true;
    } catch {
      return false;
    }
  },

  [DELETE_ROLE]: async ({ getters }, roleId) => {
    try {
      getters.isAdministrator ? await deleteRoleApi(roleId): await deleteLocalRole(roleId);
      return { successful: true };
    } catch (error) {
      const message = error?.response?.data?.message || null;
      return { successful: false, message };
    }
  },
};

const mutations = {
  [SET_ORGANISATION_ROLES]: (state, roles) => {
    state.roles = roles;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};