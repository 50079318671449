<template>
  <div :style="style" class="widget-item text-widget">
    <div
      v-if="widget.object.mode !== 'rss'"
      class="w-100 h-100 text-widget-text"
      :id="widget.object.wid"
    >
      <div v-if="widget.object.headerOn" class="header-container" :style="headerStyle">
        {{ widget.object.headerText }}
      </div>

      <div
        v-if="!widget.object.ticker"
        ref="text_field"
        :style="bodyStyle"
        v-html="this.getUserText()"
      ></div>

      <marquee
        v-else-if="!!widget.object.marqueeMode"
        class="marquee-container"
        :scrollamount="widget.object.marqueeSpeed || 10"
        truespeed
        :style="bodyStyle"
      >
        {{ widget.object.userText }}
      </marquee>

      <div
        v-else
        ref="feed_carousel"
        class="feed-carousel carousel slide h-100"
        data-ride="carousel"
        data-touch="true"
        data-wrap="true"
        data-pause="hover"
      >
        <div class="carousel-inner h-100" :style="bodyStyle">
          <div
            v-for="(line, idx) in textLines"
            :key="idx"
            class="carousel-item h-100"
            :class="{ active: idx === 0 || textRendering }"
          >
            <div class="feed-carousel-item h-100 w-100 widget-specific-auto-fit-text">
              <span class="centerText">{{ line }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import templateWidget from '@/components/templates/mixins/templateWidget';
  import { simpleTypeMixin } from '@/helpers';
  import { getRBGAColor } from '@/helpers/utils';

  const $ = window.jQuery;

  export default {
    name: 'TextWidget',

    mixins: [templateWidget, simpleTypeMixin],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        style: {},
        headerStyle: {},
        bodyStyle: {},
        textLines: [],
        textRendering: true,
      };
    },

    computed: {
      isPreview() {
        return this.$store.state.player.isPreviewPlayer;
      },
    },

    methods: {
      getUserText() {
        return this.widget.object.userText
          ? this.widget.object.userText.replace(/\n/g, '<br />')
          : '';
      },

      renderWidget() {
        const {
          alignment,
          backgroundColor,
          backgroundOpacity,
          textFont,
          headerBackgroundColor,
          headerBackgroundOpacity,
          headerTextAlignment,
          headerTextColor,
          headerTextOpacity,
          headerTextSeparation,
          headerTextSize,
          paddingHeader,
          paddingText,
          opacity,
          textColor,
          textSize,
        } = this.widget.object;

        const headerStyles = {
          backgroundColor: getRBGAColor(headerBackgroundColor, headerBackgroundOpacity / 100),
          color: getRBGAColor(headerTextColor, headerTextOpacity / 100),
          fontFamily: `${textFont}, Tahoma, Verdana, sans-serif`,
          fontSize: `${headerTextSize}px`,
          justifyContent: headerTextAlignment,
          marginBottom: `${headerTextSeparation}px`,
          padding: `${paddingHeader}px`,
          textAlign: headerTextAlignment,
          lineHeight: 1,
          ...this.headerStyle,
        };

        const bodyStyles = {
          backgroundColor: getRBGAColor(backgroundColor, backgroundOpacity / 100),
          color: getRBGAColor(textColor, opacity / 100),
          fontFamily: `${textFont}, Tahoma, Verdana, sans-serif`,
          fontSize: `${textSize}px`,
          height: '100%',
          padding: `${paddingText}px`,
          textAlign: alignment,
          lineHeight: 1,
          ...this.bodyStyle,
        };

        const style = this.defaultStyling(this.widget);

        const newStyles = {
          ...style,
          ...this.style,
        };

        this.style = newStyles;
        this.headerStyle = headerStyles;
        this.bodyStyle = bodyStyles;

        if (this.widget.object.ticker) {
          this.setupCarousel(this.widget.object);
        }
      },

      setupCarousel(widget) {
        this.textLines = widget.userText ? widget.userText.split('\n') : [];
        this.$nextTick(() => {
          $(this.$refs.feed_carousel).find('.widget-specific-auto-fit-text');
          this.textRendering = false;

          this.$nextTick(() => {
            $(this.$refs.feed_carousel).carousel('dispose');
            $(this.$refs.feed_carousel).carousel({
              interval: (widget.tickerDuration ? widget.tickerDuration : 5) * 1000,
            });
          });
        });
      },
    },

    mounted() {
      this.renderWidget();
    },
  };
</script>

<style lang="scss" scoped>
  .text-widget-text {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .text-widget {
    position: absolute;
  }
</style>
