export const RSS_APP_CREATE = 'RSS_APP_CREATE';
export const RSS_APP_CREATE_SUCCESS = 'RSS_APP_CREATE_SUCCESS';
export const RSS_APP_CREATE_ERROR = 'RSS_APP_CREATE_ERROR';
export const RSS_APP_DELETE = 'RSS_APP_DELETE';

export const APP_CREATE = 'APP_CREATE';
export const APP_CREATE_SUCCESS = 'APP_CREATE_SUCCESS';
export const APP_CREATE_ERROR = 'APP_CREATE_ERROR';
export const APP_DELETE = 'APP_DELETE';
export const APP_UPDATE = 'APP_UPDATE';
