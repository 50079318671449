import buildWidgetTree from './buildWidgetTree';

export default function generateThumbnailDataUrl(
  widgets,
  containerWidth,
  containerHeight,
  useNewWidgets = false,
) {
  // parse widgets for the first layer of z-index
  // for each widget, draw white rects with grey stroke
  // toDataUrl

  let topMostWidgets = widgets;

  if (!useNewWidgets) {
    topMostWidgets = widgets
      ? buildWidgetTree(widgets, 'root')['root'].map((wid) => widgets[wid])
      : [];
  }

  // create canvas and render rects
  const stage = new Konva.Stage({
    container: document.createElement('div'),
    width: containerWidth,
    height: containerHeight,
  });

  const layer = new Konva.Layer();

  const bg = new Konva.Rect({
    x: 0,
    y: 0,
    width: containerWidth,
    height: containerHeight,
    fill: '#E4E4E4',
    draggable: false,
  });

  layer.add(bg);

  const nodes = topMostWidgets.map((w) => {
    const x = (w.x * containerWidth) / 100;
    const y = (w.y * containerHeight) / 100;
    const width = (w.width * containerWidth) / 100;
    const height = (w.height * containerHeight) / 100;

    return new Konva.Rect({
      x,
      y,
      width,
      height,
      fill: '#EFEFEF',
      stroke: '#FFF',
      strokeWidth: 2,
      draggable: false,
    });
  });

  nodes.forEach((n) => layer.add(n));

  stage.add(layer);

  return stage.toDataURL();
}
