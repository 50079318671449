import axios from 'axios';
import Vue from 'vue';
import {
  apiGetScreenGroups,
  apiGetScreens,
  apiGetScreenFromScreenGroup,
  apiAddScreenGroup,
  apiDeleteScreenGroup,
  apiAddScreen,
  apiDeleteScreen,
  restartScreens,
  rotateScreens,
  apiGetScreenLayout,
  getLicenses,
  apiPutScreenGroup,
  apiPutScreen,
  apiAddScreenLayout,
  apiGetScreen,
  getUserLicenses,
  apiGetScreensStatus,
  apiGetScreenGroupHeriarchy,
} from '@/api/screens';
import {
  SCREENS_FETCH,
  SCREENS_FETCH_ERROR,
  SCREENS_FETCH_SUCCESS,
  SCREEN_GROUPS_FETCH,
  GROUP_SCREEN_GROUP_SCREENS,
  GROUP_SCREENS_FETCH,
  SCREEN_GROUPS_FETCH_SUCCESS,
  SCREEN_GROUPS_FETCH_ERROR,
  SCREENS_ADD_GROUP,
  SCREEN_GROUP_UPDATE,
  SCREENS_ADD,
  SCREENS_ADD_SUCCESS,
  SCREENS_RESTART,
  SCREENS_RESTART_SUCCESS,
  SCREEN_DELETE,
  SCREEN_DELETE_SUCCESS,
  SCREENS_FILTERS_SET,
  SCREENS_ROTATE,
  SCREENS_ROTATE_SUCCESS,
  SCREEN_GROUP_DELETE,
  SCREEN_LAYOUT_FETCH,
  SCREENS_RESET,
  SCREEN_GET_ALL_LICENSES,
  SCREEN_UPDATE,
  SCREEN_GET,
  SCREEN_ADD_LAYOUT,
  SET_SCREEN,
  SET_SCREEN_GROUP,
  SCREENS_STATUS_FETCH,
  SCREENS_STATUS_FETCH_SUCCESS,
  SCREENS_STATUS_FETCH_ERROR,
  SCREENS_STATUS_UPDATE,
} from '@/store/actions/screens';

const getScreensFromHierarchy = (data) => {
  let screens = data.children_screens;

  data.children_screen_groups.forEach((screenGroup) => {
    const subScreens = getScreensFromHierarchy(screenGroup);
    screens = [...screens, ...subScreens];
  });

  return screens;
};

const initialState = {
  filters: {
    name: '',
    orientation: '',
    online: '',
    deviceType: '',
  },
  screenGroups: { data: [], error: null, loading: false },
  screensStatus: { data: [], statuses: {}, error: null, loading: false },
  screens: { data: {}, error: {}, loading: {} },
  screen: null,
  screenGroup: null,
};

const actions = {
  [SCREEN_GROUPS_FETCH]: ({ commit }) => {
    commit(SCREEN_GROUPS_FETCH);
    return apiGetScreenGroups().then(
      (data) => {
        commit(SCREEN_GROUPS_FETCH_SUCCESS, data);
      },
      (error) => {
        commit(SCREEN_GROUPS_FETCH_ERROR, error);
      },
    );
  },

  [SCREENS_STATUS_FETCH]: ({ commit }) => {
    commit(SCREENS_STATUS_FETCH);
    return apiGetScreensStatus()
      .then((data) => {
        commit(SCREENS_STATUS_FETCH_SUCCESS, data);
      })
      .catch((error) => {
        commit(SCREENS_STATUS_FETCH_ERROR, error);
      });
  },

  [SCREEN_GROUP_UPDATE]: (_, { groupId, screenData }) => {
    return new Promise((resolve, reject) => {
      const res = apiPutScreenGroup(groupId, screenData);
      res
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Fetch screens for a certain group
  [SCREENS_FETCH]: ({ commit, state }, groupId) => {
    commit(SCREENS_FETCH, groupId);
    return new Promise((resolve) => {
      const res = apiGetScreens({ ...state.filters, screen_group_id: groupId });

      res
        .then((data) => {
          commit(SCREENS_FETCH_SUCCESS, { data, groupId });
          resolve(data);
        })
        .catch((error) => {
          commit(SCREENS_FETCH_ERROR, { error, groupId });
        });
    });
  },

  [GROUP_SCREENS_FETCH]: ({ commit, state }, groupId) => {
    return new Promise((resolve, reject) => {
      const res = apiGetScreenFromScreenGroup(groupId);
      res
        .then((data) => {
          commit(SCREENS_FETCH_SUCCESS, { data, groupId });
          resolve(data);
        })
        .catch((error) => {
          commit(SCREENS_FETCH_ERROR, { error, groupId });
          reject(error);
        });
    });
  },

  [GROUP_SCREEN_GROUP_SCREENS]: ({ commit, state }, groupId) => {
    return new Promise((resolve, reject) => {
      const res = apiGetScreenGroupHeriarchy(groupId);
      res
        .then((data) => {
          const screens = getScreensFromHierarchy(data);
          commit(SCREENS_FETCH_SUCCESS, { data: screens, groupId });
          resolve(screens);
        })
        .catch((error) => {
          commit(SCREENS_FETCH_ERROR, { error, groupId });
          reject(error);
        });
    });
  },

  [SCREEN_LAYOUT_FETCH]: ({}, screenId) => {
    return new Promise((resolve) => {
      const res = apiGetScreenLayout(screenId);
      res
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  // Add new screen group
  [SCREENS_ADD_GROUP]: async ({ commit, dispatch }, screenData) =>
    new Promise((resolve) => {
      const res = apiAddScreenGroup(screenData);
      res.then((data) => {
        dispatch(SCREEN_GROUPS_FETCH).then(() => {
          resolve(data);
        });
      });
    }),

  [SCREEN_GROUP_DELETE]: async ({ commit }, groupId) =>
    new Promise((resolve, reject) => {
      const res = apiDeleteScreenGroup(groupId);
      res
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  // Add new screen
  [SCREENS_ADD]: async ({ commit }, screen) =>
    new Promise((resolve, reject) => {
      const res = apiAddScreen(screen);

      res
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          if (axios.isAxiosError(e)) {
            // get axios response
            const { response } = e;
            if (response) {
              reject(new Error(response.data.message));
            }
          }
        });
    }),

  // Update screen
  [SCREEN_UPDATE]: async ({ commit }, { screenId, ...screenData }) =>
    new Promise((resolve, reject) => {
      const res = apiPutScreen(screenId, screenData);
      res
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    }),
  [SCREEN_GET]: async ({ commit }, screenId) => {
    const data = await apiGetScreen(screenId);
    return data;
  },
  [SCREEN_ADD_LAYOUT]: async ({ commit }, { screenId, layoutId }) =>
    new Promise((resolve, reject) => {
      const res = apiAddScreenLayout(screenId, layoutId);
      res
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    }),

  [SCREENS_ROTATE]: async ({ commit }, screens) => {
    const res = rotateScreens(screens);
    return res.then((data) => {
      commit(SCREENS_RESTART_SUCCESS, data);
    });
  },

  [SCREENS_RESTART]: async ({ commit }, { publicationType, data }) =>
    new Promise((resolve, reject) => {
      const res = restartScreens(publicationType, data);

      return res
        .then((data) => {
          resolve(data);
          // commit(SCREENS_RESTART_SUCCESS, data);
        })
        .catch((e) => reject(e));
    }),

  [SCREEN_DELETE]: async ({ commit, dispatch }, screenId) => {
    const res = apiDeleteScreen(screenId);
    return res.then((data) => {
      commit(SCREEN_DELETE_SUCCESS, data);
    });
  },

  [SCREENS_FILTERS_SET]: ({ commit, dispatch, state }, filters) => {
    commit(SCREENS_FILTERS_SET, { ...state.filters, ...filters });
    const screenGroups = state.screenGroups.data;
    if (screenGroups.length > 0) {
      screenGroups.forEach((group) => {
        dispatch(SCREENS_FETCH, group.id);
      });
    }
  },
  [SCREEN_GET_ALL_LICENSES]: ({ rootState }, organizationId) =>
    new Promise((resolve, reject) => {
      let isSuperAdmin = rootState.auth.is_administrator;
      var res;
      if (!isSuperAdmin) {
        res = getUserLicenses();
      } else {
        res = getLicenses(organizationId);
      }

      res
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }),
};

const mutations = {
  [SCREEN_GROUPS_FETCH]: (state) => {
    state.screenGroups.loading = true;
  },

  [SCREEN_GROUPS_FETCH_SUCCESS]: (state, data) => {
    state.screenGroups.data = data;
    state.screenGroups.loading = false;
  },

  [SCREEN_GROUPS_FETCH_ERROR]: (state, error) => {
    state.screenGroups.error = error;
    state.screenGroups.loading = false;
  },

  [SCREENS_FETCH]: (state, groupId) => {
    Vue.set(state.screens.loading, groupId, true);
  },

  [SCREENS_FETCH_SUCCESS]: (state, { groupId, data }) => {
    Vue.set(state.screens.data, groupId, data);
    Vue.set(state.screens.data, groupId, data);
    Vue.set(state.screens.loading, groupId, false);
    Vue.set(state.screens.error, groupId, null);
  },

  [SCREENS_FETCH_ERROR]: (state, { groupId, error }) => {
    Vue.set(state.screens.loading, groupId, false);
    Vue.set(state.screens.error, groupId, error);
  },

  [SCREENS_STATUS_FETCH]: (state) => {
    Vue.set(state.screensStatus, 'loading', true);
  },

  [SCREENS_STATUS_FETCH_SUCCESS]: (state, data) => {
    const statuses = {};
    data.forEach((screen) => {
      statuses[screen.id] = screen;
    });

    Vue.set(state.screensStatus, 'data', data);
    Vue.set(state.screensStatus, 'statuses', statuses);
    Vue.set(state.screensStatus, 'loading', false);
    Vue.set(state.screensStatus, 'error', null);
  },

  [SCREENS_STATUS_FETCH_ERROR]: (state, error) => {
    Vue.set(state.screensStatus, 'loading', false);
    Vue.set(state.screensStatus, 'error', error);
  },

  [SCREENS_STATUS_UPDATE]: (state, data) => {
    let screenStatus = state.screensStatus.data.find((screen) => screen.id === data.screen);

    if (!screenStatus) return;

    screenStatus.connection_status = data.connection_status;

    Vue.set(state.screensStatus, 'data', state.screensStatus.data);
    Vue.set(state.screensStatus.statuses, data.screen, screenStatus);
  },

  [SCREENS_ADD_SUCCESS]: (state, { groupId, data }) => {
    state.screens.data[groupId].push(data);
  },

  [SCREENS_ROTATE_SUCCESS]: (state, data) => {
    state.screenGroups = data;
  },

  [SCREENS_RESTART_SUCCESS]: (state, data) => {
    state.screenGroups = data;
  },

  [SCREEN_DELETE_SUCCESS]: (state) => {
    state.status = 'success';
  },

  // Filters
  [SCREENS_FILTERS_SET]: (state, filters) => {
    state.filters = filters;
  },

  [SCREENS_RESET]: (state) => {
    state = initialState;
  },

  [SET_SCREEN]: (state, screen) => {
    state.screen = screen;
  },

  [SET_SCREEN_GROUP]: (state, screenGroup) => {
    state.screenGroup = screenGroup;
  },
};

export const getters = {
  screenGroupScreens: (state) => (groupId) => state.screens.data[groupId],
  screenGroupLoading: (state) => (groupId) => state.screens.loading[groupId],
  screenGroupError: (state) => (groupId) => state.screens.error[groupId],
  getScreenStatus: (state) => (screenId) => {
    const screen = state.screensStatus.data.find((screen) => screen.id === screenId);
    return screen ? screen.connection_status : null;
  },
  getScreenGroupStatus: (state) => (groupId) => {
    const status = state.screensStatus.data.filter((screen) => screen.screen_group === groupId);

    const screens = {
      disconnected: status.filter((screen) => screen.connection_status === 0).length,
      idle: status.filter((screen) => screen.connection_status === 1).length,
      connected: status.filter((screen) => screen.connection_status === 2).length,
    };

    return screens;
  },
};

export default {
  state: initialState,
  actions,
  mutations,
  getters,
};
