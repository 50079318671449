<template>
  <component
    @show-enlarged="showEnlarged"
    :is="`${node.data.object.type}-widget`"
    :widget="node.data"
    @click.native="selectWidgetFromPlayer"
    :class="{
      disabled: !node.data.has_access && isOverridePage,
      'default-cursor': !this.isOverridePage,
    }"
  />
</template>

<script>
  import LayerWidget from '@/components/playerWidgets/LayerWidget.vue';
  import TextWidget from '@/components/playerWidgets/TextWidget.vue';
  import ClockWidget from '@/components/playerWidgets/ClockWidget.vue';
  import ButtonWidget from '@/components/playerWidgets/ButtonWidget.vue';
  import ImageWidget from '@/components/playerWidgets/ImageWidget.vue';
  import PlaylistWidget from '@/components/playerWidgets/PlaylistWidget.vue';
  import AppWidget from '@/components/playerWidgets/AppWidget.vue';
  import VideoWidget from '@/components/playerWidgets/VideoWidget.vue';
  import PdfWidget from '@/components/playerWidgets/PdfWidget.vue';
  import TemplateWidget from '@/components/playerWidgets/TemplateWidget.vue';
  import RichtextWidget from './RichTextWidget.vue';
  import { SET_SELECTED_WIDGET } from '@/store/actions/player';

  export default {
    name: 'WidgetNode',
    props: {
      node: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    methods: {
      showEnlarged(w) {
        this.$emit('show-enlarged', w);
      },

      selectWidgetFromPlayer() {
        if (!this.isOverridePage) return;
        if (this.node.data.has_access) this.$store.commit(SET_SELECTED_WIDGET, this.node.data);
      },
    },
    computed: {
      isOverridePage() {
        return (
          !!this.$route.params?.screenId ||
          (!!this.$route.params?.groupId && !!this.$route.params?.layoutId)
        );
      },
    },
    components: {
      ImageWidget,
      LayerWidget,
      TextWidget,
      ClockWidget,
      ButtonWidget,
      PlaylistWidget,
      AppWidget,
      VideoWidget,
      PdfWidget,
      TemplateWidget,
      RichtextWidget,
    },
  };
</script>

<style scoped>
  .default-cursor {
    cursor: auto !important;
  }
  .disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
</style>
