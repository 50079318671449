var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"TableAppWrapper",staticClass:"table-wrapper",class:{
    black: _vm.settings.styles.table.theme === 'black-white',
    expandRows: _vm.currentPage === _vm.pagesInTotal,
  },style:({
    backgroundColor: _vm.settings.styles.table.backgroundColor,
  })},[_c('div',{ref:"TableApp",staticClass:"rows-container"},_vm._l((_vm.pageRows),function(row,index){return _c('div',{key:index,staticClass:"table-row",style:(_vm.rowStyle(row))},_vm._l((row.data),function(cell,indexCol){return _c('div',{key:indexCol,staticClass:"table-col",style:({
          justifyContent: _vm.settings.styles[row.type].alignment || 'center',
        })},[_vm._v(" "+_vm._s(cell.content)+" ")])}),0)}),0),(_vm.pagesInTotal > 1)?_c('div',{staticClass:"table-pagination"},[_c('ul',_vm._l((_vm.pagesInTotal),function(pageIndex){return _c('li',{key:pageIndex,class:{ active: pageIndex === _vm.currentPage }})}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }