var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rss"},[(_vm.rssRequestStatus === 'loading')?_c('img',{staticClass:"rss-loading",attrs:{"src":require("@/assets/img/rss/preloader.svg")}}):(_vm.rssRequestStatus === 'error' && _vm.rssRequestError)?_c('div',{staticClass:"rss-error p-3"},[_c('h5',[_vm._v(_vm._s(_vm.rssRequestError))])]):(_vm.rssRequestStatus === 'success' && _vm.rssFeed)?[_c('transition-group',{staticStyle:{"width":"100%","height":"100%","overflow":"hidden"},style:({ zoom: _vm.isPreview ? 0.5 : 1 }),attrs:{"name":"fade","mode":"in-out","duration":500,"tag":"div"}},[_vm._l((_vm.rssFeed.items),function(item,index){return [(index === _vm.currentIndex)?_c('div',{key:index,staticClass:"rss-slides",class:[_vm.setting.rssTheme]},[_c('div',{staticClass:"slide-container"},[(_vm.setting.display.image)?_c('div',{staticClass:"item-image",style:({ width: `${_vm.setting.display.imageWidth}%` })},[_c('img',{style:({ objectFit: _vm.setting.display.imageMode || 'cover' }),attrs:{"src":item.teaserImage || _vm.rssFeed.image || '../../assets/img/rss/rss_invalid.svg',"alt":item.title}})]):_vm._e(),_c('div',{staticClass:"item-body",style:({
                width: `${_vm.setting.display.image ? 100 - _vm.setting.display.imageWidth : 100}%`,
                gap: `${_vm.setting.display.spacing}px`,
                padding: `${_vm.setting.display.padding}px`,
              })},[(_vm.setting.display.rssName)?_c('div',{staticClass:"item-header"},[_c('div',{staticClass:"rss-icon-container"},[_c('img',{staticClass:"rss-icon",attrs:{"src":require("@/assets/img/rss/rss-icon.png")}})]),_c('div',{staticClass:"header-title",style:({
                    fontSize: `${_vm.setting.display.feedNameSize}px`,
                    lineHeight: `${_vm.setting.display.feedNameSize * 1.2}px`,
                    color: _vm.setting.rssTheme === 'black' ? '#ffbf5f' : 'inherit',
                  })},[_vm._v(" "+_vm._s(_vm.rssOwner)+" ")])]):_vm._e(),(item.title)?_c('div',{staticClass:"item-title scroolbar",style:({
                  fontSize: `${_vm.setting.display.titleFontSize}px`,
                  lineHeight: `${_vm.setting.display.titleFontSize * 1.2}px`,
                })},[(item.pubDate)?_c('div',{staticClass:"item-date",style:({
                    fontSize: `${
                      _vm.setting.display.titleFontSize > 16
                        ? _vm.setting.display.titleFontSize * 0.6
                        : 12
                    }px`,
                    color: _vm.setting.rssTheme === 'black' ? '#ffbf5f' : 'inherit',
                  })},[_vm._v(" "+_vm._s(_vm._f("relativeDay")(item.pubDate))+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),_c('div',{staticClass:"description-container scrollbar"},[(_vm.setting.display.content)?_c('div',{staticClass:"item-description",style:({
                    fontSize: `${_vm.setting.display.contentSize}px`,
                    lineHeight: `${_vm.setting.display.contentSize * 1.2}px`,
                  })},[_vm._v(" "+_vm._s(_vm.rssContent)+" ")]):_vm._e()])])])]):_vm._e()]})],2)]:_c('div',{staticClass:"no-rss"},[_c('img',{staticClass:"rss-invalid",attrs:{"src":require("../../assets/img/rss/rss_invalid.svg")}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }