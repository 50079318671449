export const USER_REQUEST = 'USER_REQUEST';
export const USERS_REQUEST = 'USERS_REQUEST';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_ERROR = 'USER_GET_ERROR';
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS';
export const USERS_GET_ERROR = 'USERS_GET_ERROR';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPLOAD_AVATAR = 'USER_UPLOAD_AVATAR';
export const USER_ADD = 'USER_ADD';
export const USER_ADD_ERROR = 'USER_ADD_ERROR';
export const USER_ADD_SUCCESS = 'USER_ADD_SUCCESS';
export const USER_DELETE = 'USER_DELETE';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';
export const USER_RESET_STATUS = 'USER_RESET_STATUS';

export const ADMIN_USER_ADD = 'ADMIN_USER_ADD';
export const ADMIN_USER_ADD_SUCCESS = 'ADMIN_USER_ADD_SUCCESS';
export const ADMIN_USER_ADD_ERROR = 'ADMIN_USER_ADD_ERROR';

export const ADMIN_USERS_REQUEST = 'ADMIN_USERS_REQUEST';
export const ADMIN_USERS_REQUEST_SUCCESS = 'ADMIN_USERS_REQUEST_SUCCESS';
export const ADMIN_USERS_REQUEST_ERROR = 'ADMIN_USERS_REQUEST_ERROR';

export const ADMIN_USER_UPDATE = 'ADMIN_USER_UPDATE';
export const ADMIN_USER_UPDATE_SUCCESS = 'ADMIN_USER_UPDATE_SUCCESS';
export const ADMIN_USER_UPDATE_ERROR = 'ADMIN_USER_UPDATE_ERROR';

export const ADMIN_USER_DELETE = 'ADMIN_USER_DELETE';
export const ADMIN_USER_DELETE_SUCCESS = 'ADMIN_USER_DELETE_SUCCESS';
export const ADMIN_USER_DELETE_ERROR = 'ADMIN_USER_DELETE_ERROR';
