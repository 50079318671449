const defaultOptions = {
  playerSite: false,
  awsS3sslEnabled: true,
  timezone: 'Europe/Stockholm',
};

if (process.env.VUE_APP_PLAYER === 'true') {
  defaultOptions.playerSite = true;
  defaultOptions.awsS3sslEnabled = false;
}

export default {
  locale: process.env.VUE_APP_LOCALE,
  baseUrl:
    process.env.VUE_APP_IMAGE_BASE_URL ||
    `${location.protocol}//s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${process.env.VUE_APP_AWS_S3_BUCKET}`,
  s3BaseUrl: `https://s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${process.env.VUE_APP_AWS_S3_BUCKET}/`,
  alternateS3BaseUrl: `https://${process.env.VUE_APP_AWS_S3_BUCKET}.s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/`,
  playerBaseUrl: process.env.VUE_APP_PLAYER_BASE_URL,
  apiUrl: process.env.VUE_APP_API_URL,
  socketUrl: process.env.VUE_APP_SOCKET_URL,
  transportApiUrl: process.env.VUE_APP_TRANSPORT_API_URL,
  ...defaultOptions,
};
