import axios from 'axios';

import config from '../config';

axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token');

export function apiReadLayouts(query = {}, getLayoutTemplates) {
  const endpoint = `${config.apiUrl}userapi/layout`;

  query.is_prd_layout = getLayoutTemplates ? true : false;

  return axios.get(endpoint, { params: query });
}

export function apiReadLayout(id) {
  const endpoint = `${config.apiUrl}userapi/layout/${id}`;
  return axios.get(endpoint);
}

export function apiGetScreenLayout(screenId) {
  const endpoint = `${config.apiUrl}userapi/screens/${screenId}/layout`;
  return axios.get(endpoint);
}

export function apiDeleteLayout(id) {
  const endpoint = `${config.apiUrl}userapi/layout/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .delete(endpoint)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
}

export function apiUpdateLayout(id, settings) {
  const endpoint = `${config.apiUrl}userapi/layout/${id}`;
  return axios.put(endpoint, {
    name: settings.name,
    settings,
  });
}

export function apiCreateLayout(settings) {
  const endpoint = `${config.apiUrl}userapi/layout`;
  return axios.post(endpoint, {
    name: settings.name,
    settings,
  });
}

// ---- LAYOUTS CHILDREN ---- //

export function apiGetLayoutChildren(layoutId) {
  const endpoint = `${config.apiUrl}userapi/layout/${layoutId}/children`;
  return axios.get(endpoint);
}

export function apiAttachLayoutChild({ layoutId, widgetData }) {
  const endpoint = `${config.apiUrl}userapi/layout/${layoutId}/children`;
  return axios.post(endpoint, widgetData);
}

export function apiUpdateLayoutChild(layoutId, widgetData) {
  const endpoint = `${config.apiUrl}userapi/layout/${layoutId}/children`;
  return axios.put(endpoint, widgetData);
}

export function apiRemoveChild(layoutId, data) {
  const endpoint = `${config.apiUrl}userapi/layout/${layoutId}/children`;
  return axios.delete(endpoint, {
    data,
  });
}

export function apiCreateAppWidget(widgetData) {
  const endpoint = `${config.apiUrl}userapi/app`;
  return axios.post(endpoint, {
    ...widgetData,
  });
}

export function apiCreateLayerWidget(widgetData) {
  const endpoint = `${config.apiUrl}userapi/layer`;
  return axios.post(endpoint, {
    ...widgetData,
  });
}

export function apiUpdateLayerWidget(layerId, widgetData) {
  const endpoint = `${config.apiUrl}userapi/layer/${layerId}`;
  return axios.put(endpoint, {
    ...widgetData,
  });
}

export function apiUpdateAppWidget(appId, widgetData) {
  const endpoint = `${config.apiUrl}userapi/app/${appId}`;
  return axios.put(endpoint, {
    ...widgetData,
  });
}

export function apiReplaceChildItem(layoutId, widgetData) {
  const endpoint = `${config.apiUrl}userapi/layout/${layoutId}/children/replace`;
  return axios.put(endpoint, {
    ...widgetData,
  });
}

// ---- LAYOUTS ROLES ---- //

export function apiGetLayoutWidgetRoles(itemId, itemType) {
  const endpoint = `${config.apiUrl}userapi/${itemType}/${itemId}/roles-access`;
  return axios.get(endpoint);
}

export function apiSaveLayoutWidgetRoles(itemId, itemType, roles) {
  const endpoint = `${config.apiUrl}userapi/${itemType}/${itemId}/roles-access`;
  return axios.post(endpoint, { roles });
}

// ---- LAYOUT TEMPLATES ---- //

export function apiCreateLayoutTemplate(layoutId) {
  const endpoint = `${config.apiUrl}userapi/pred-layout/${layoutId}/create`;

  return axios.post(endpoint);
}

export function apiCreateLayoutFromTemplate(layoutId) {
  const endpoint = `${config.apiUrl}userapi/layout/${layoutId}/clone`;

  return axios.post(endpoint);
}
