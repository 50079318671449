import {
  UPLOAD_USER_FONT,
  SET_USER_FONTS,
  GET_USER_FONTS,
  DELETE_USER_FONT,
} from '@/store/actions/fonts';

import { getFontsApi, uploadFontApi, deleteFontApi } from '@/api/fonts';
import { getFont } from '@/helpers/mixins';

const savedFonts = sessionStorage['userFonts'];

const state = {
  userFonts: !!savedFonts ? JSON.parse(savedFonts) : [],
  fontsLoaded: !!savedFonts,
};

const getters = {
  userFonts: (state) => state.userFonts,
};

const actions = {
  [GET_USER_FONTS]: async ({ commit }) => {
    try {
      const response = await getFontsApi();

      const fonts = response.data;

      fonts.forEach(getFont);

      commit(SET_USER_FONTS, fonts);
    } catch (error) {
      console.error('GET_USER_FONTS: ', error);
    }
  },

  [UPLOAD_USER_FONT]: async ({ dispatch }, data) => {
    const response = await uploadFontApi(data);
    await dispatch(GET_USER_FONTS);
  },

  [DELETE_USER_FONT]: async ({ dispatch }, fontId) => {
    try {
      const response = await deleteFontApi(fontId);
      await dispatch(GET_USER_FONTS);

      return true;
    } catch (error) {
      console.error('DELETE_USER_FONT: ', error);
      return false;
    }
  },
};

const mutations = {
  [SET_USER_FONTS]: (state, userFonts) => {
    state.userFonts = userFonts;
    state.fontsLoaded = true;

    sessionStorage[('userFonts', JSON.stringify(userFonts))];
  },
};

export default {
  getters,
  state,
  actions,
  mutations,
};
