<template>
  <loader v-if="isLoading" />

  <div v-else-if="currentSlide && filteredSlides.length > 0" class="playlist-player">
    <div
      v-for="(slide, index) in filteredSlides"
      class="playlist-slide"
      :class="{
        [slideClassIn]: displaySlide(slide),
        [slideClassOut]: !displaySlide(slide),
      }"
      :key="`${slide.item_id}-${playlist.playlist_id}-${index}`"
    >
      <PlaylistSlide
        :slide="{ ...slide, imageMode: playlist.imageMode || 'cover' }"
        :isCurrentSlide="slide.item_id === currentSlide.item_id"
        :trafiklabZoom="trafiklabZoom"
        :playlist="playlist"
      />
    </div>

    <video
      v-if="(videoSlides.length > 0 && slideVideo1) || showVideoPreview"
      loop
      muted
      :ref="`video-${slideVideo1 ? slideVideo1.item_id : videoSlides[0].item_id}`"
      class="playlist-slide-video"
      :class="{
        [slideClassIn]: showVideo1,
        [slideClassOut]: !showVideo1,
      }"
      :style="{
        objectFit: playlist?.imageMode || 'cover',
        visibility:
          showVideoPreview || currentSlide?.item_id === slideVideo1?.item_id ? 'visible' : 'hidden',
      }"
      :src="getEncodedURL(baseUrl, (!showVideoPreview ? slideVideo1 : currentSlide)?.item_url)"
      @play="playingVideo1"
    ></video>

    <video
      v-if="videoSlides.length > 1 && slideVideo2"
      loop
      muted
      :ref="`video-${slideVideo2?.item_id}`"
      class="playlist-slide-video"
      :class="{
        [slideClassIn]: showVideo2,
        [slideClassOut]: !showVideo2,
      }"
      :style="{
        objectFit: playlist?.imageMode || 'cover',
        visibility: currentSlide?.item_id === slideVideo2.item_id ? 'visible' : 'hidden',
      }"
      :src="getEncodedURL(baseUrl, slideVideo2.item_url)"
      @play="playingVideo2"
    ></video>
  </div>

  <div v-else class="playlist-background"></div>
</template>

<script>
  import moment from 'moment';

  import { GET_PLAYLIST_ITEMS } from '@/store/actions/playlist';
  import { SET_PLAYLIST_UPDATE_STATE } from '@/store/actions/player';

  import PlaylistSlide from '@/components/player/PlaylistSlide.vue';
  import Loader from '@/components/common/Loader.vue';

  import { DAYS_OF_THE_WEEK, DISPLAY_PRIORITY } from '@/constant/const';

  import { simpleTypeMixin } from '@/helpers';

  export default {
    name: 'PlaylistPlayer',

    mixins: [simpleTypeMixin],

    props: {
      playSlider: {
        type: Boolean,
        default: true,
      },
      isPlaylistPreview: {
        type: Boolean,
        default: false,
      },
      designerMode: {
        type: Boolean,
        default: false,
      },
      playlist: {
        type: Object,
        default: () => null,
      },
      trafiklabZoom: {
        type: Number,
        default: 1,
      },
      transitionMode: {
        type: String,
        default: '',
      },
    },

    components: {
      PlaylistSlide,
      Loader,
    },

    data() {
      return {
        currentSlideIndex: 0,
        playlistItems: [],
        time: null,
        filteredSlides: [],
        playVideo1: true,
        slideVideo1: null,
        slideVideo2: null,
        isLoading: false,
      };
    },

    watch: {
      playlistId() {
        this.setPlaylist();
      },

      playSlider(play) {
        if (play) {
          this.initPlaylist();
        } else {
          this.pausePlayer();
        }
      },

      async updatePlaylists(shouldUpdate) {
        if (shouldUpdate) {
          await this.setPlaylist();

          if (this.isPlaylistPreview) this.pausePlayer();

          if (this.slideVideo1)
            this.slideVideo1 =
              this.videoSlides.find((slide) => slide.item_id === this.slideVideo1.item_id) || null;

          if (this.slideVideo2)
            this.slideVideo2 =
              this.videoSlides.find((slide) => slide.item_id === this.slideVideo2.item_id) || null;

          this.$store.commit(SET_PLAYLIST_UPDATE_STATE, false);
        }
      },
    },

    async mounted() {
      if (!this.playlist?.playlist_id && !this.playlist.wid) return;

      this.setPlaylist();

      const filter = this.filterPlaylistsBySchedulerAndPriority;

      this.time = setInterval(function () {
        filter();
      }, 10 * 1000);
    },

    beforeDestroy() {
      clearInterval(this.time);
    },

    computed: {
      isLayoutDesigner() {
        return this.$route.name === 'Layout';
      },

      playlistId() {
        return this.playlist?.playlist_id;
      },

      updatePlaylists() {
        return this.$store.state.player.updatePlaylists;
      },

      currentSlide() {
        return this.filteredSlides[this.currentSlideIndex] || null;
      },

      slides() {
        return !this.isLayoutDesigner
          ? this.playlistItems
          : this.$store.getters.getPlaylistItems(this.playlist.wid);
      },

      videoSlides() {
        return this.filteredSlides.filter((item) => item.item_type?.includes('video'));
      },

      showVideoPreview() {
        return !this.playSlider && this.currentSlide?.item_type.includes('video');
      },

      showVideo1() {
        return this.showVideoPreview || this.currentSlide?.item_id === this.slideVideo1?.item_id;
      },

      showVideo2() {
        return this.slideVideo2 && this.currentSlide?.item_id === this.slideVideo2.item_id;
      },

      slideTransition() {
        return this.transitionMode || this.playlist.transition_mode;
      },

      slideClassIn() {
        return `${this.slideTransition}In`;
      },

      slideClassOut() {
        return `${this.slideTransition}Out`;
      },
    },

    methods: {
      async getPlaylistData() {
        try {
          if (this.isLayoutDesigner && !!this.slides) {
            return;
          }

          this.isLoading = true;

          const response = await this.$store.dispatch(GET_PLAYLIST_ITEMS, {
            playlist_id: this.playlistId,
            wid: this.playlist.wid,
            saveItems: this.isLayoutDesigner,
          });

          this.playlistItems = !this.isLayoutDesigner ? response.data.items : [];
        } catch (error) {
          console.error('error: ', error);
        }

        this.isLoading = false;
      },

      async setPlaylist() {
        await this.getPlaylistData();

        this.filterPlaylistsBySchedulerAndPriority();
        this.currentSlideIndex = 0;

        if (this.playSlider && this.playlistItems.length > 0) {
          this.initPlaylist();
        }
      },

      playingVideo1() {
        if (this.videoSlides.length < 2) return;

        let currentSlideId = 0;

        this.videoSlides.forEach((slide, index) => {
          if (slide.item_id === this.slideVideo1.item_id) {
            currentSlideId = index;
          }
        });

        const nextSlideId = this.videoSlides.length > currentSlideId + 1 ? currentSlideId + 1 : 0;
        this.slideVideo2 = this.videoSlides[nextSlideId];
      },

      playingVideo2() {
        if (this.videoSlides.length < 2) return;

        let currentSlideId = 0;

        this.videoSlides.forEach((slide, index) => {
          if (slide.item_id === this.slideVideo2.item_id) {
            currentSlideId = index;
          }
        });

        const nextSlideId = this.videoSlides.length > currentSlideId + 1 ? currentSlideId + 1 : 0;
        this.slideVideo1 = this.videoSlides[nextSlideId];
      },

      filterPlaylistsBySchedulerAndPriority() {
        const tempFilteredSlides = (this.slides || []).filter((slide) => {
          if (!slide.scheduler || slide.scheduler.available === 'always') {
            return true;
          }

          const { type, timeToTime, daysTime, daysTimes, daysHourType, days } = slide.scheduler;

          const fromDate = timeToTime.from ? moment(timeToTime.from).toDate() : null;
          const toDate = timeToTime.to ? moment(timeToTime.to).toDate() : null;

          const isFromDateValid = fromDate ? moment(fromDate).isBefore() : true;
          const isToDateValid = toDate ? moment(toDate).isAfter() : true;

          const isInRangeOfDays = isFromDateValid && isToDateValid;

          if (!isInRangeOfDays || type === 'time-to-time') {
            return isInRangeOfDays;
          }

          const now = moment();
          const currentDay = DAYS_OF_THE_WEEK[now.isoWeekday() - 1];
          const midnight = now.clone().startOf('day');
          const currentMinute = now.diff(midnight, 'minutes');

          const isValidDay =
            (daysHourType === 'same-hours' && days.length === 0) || days.includes(currentDay);

          if (!isValidDay) {
            return false;
          }

          if (daysHourType === 'same-hours') {
            return currentMinute >= daysTime[0] && currentMinute <= daysTime[1];
          }

          const currentRangeOfMinutes = daysTimes[currentDay];

          return currentMinute >= currentRangeOfMinutes && currentMinute <= currentRangeOfMinutes;
        });

        const hasPriorityItems =
          tempFilteredSlides.filter((slide) => slide.display_priority === DISPLAY_PRIORITY.PRIORITY)
            .length > 0;
        this.filteredSlides = tempFilteredSlides.filter((slide) =>
          hasPriorityItems ? slide.display_priority === DISPLAY_PRIORITY.PRIORITY : true,
        );
      },

      initPlaylist() {
        if (this.currentSlideIndex === 0) {
          this.startSlider();
        } else {
          this.initTimer(this.currentSlide.display_timer * 1000);
        }
        this.checkAndPlayVideo();
      },

      startSlider() {
        if (this.filteredSlides.length === 0) {
          return this.initTimer(1000);
        }

        const firstSlide = this.filteredSlides[0];

        if (this.videoSlides.length) {
          this.slideVideo1 = this.videoSlides[0];
        }

        this.checkAndPlayVideo();
        this.initTimer(firstSlide.display_timer * 1000);
      },

      initTimer(after) {
        const slideDuration = after > 1000 ? after : 1000;

        this.timer = setTimeout(this.nextSlide, slideDuration);
      },

      destroyTimer() {
        clearTimeout(this.timer);
      },

      pausePlayer() {
        this.destroyTimer();

        if (this.currentSlide?.item_type.includes('video')) {
          const video = this.$refs[`video-${this.currentSlide.item_id}`];
          video?.pause();
        }
      },

      nextSlide() {
        if (this.currentSlide?.item_type.includes('video')) {
          const video = this.$refs[`video-${this.currentSlide.item_id}`];
          video?.pause();
        }

        const newSlideIndex =
          this.currentSlideIndex + 1 < this.filteredSlides.length ? this.currentSlideIndex + 1 : 0;

        this.destroyTimer();

        // Minimizing reactivity
        if (newSlideIndex !== this.currentSlideIndex) {
          this.currentSlideIndex = newSlideIndex;
          this.checkAndPlayVideo();
        }

        this.initTimer((this.currentSlide ? this.currentSlide.display_timer : 1) * 1000);
      },

      checkAndPlayVideo() {
        if (!this.videoSlides.length || !this.currentSlide?.item_type.includes('video')) return;

        let video;

        if (this.currentSlide.item_id === this.slideVideo1?.item_id) {
          video = this.$refs[`video-${this.currentSlide.item_id}`];
        } else if (this.currentSlide.item_id === this.slideVideo2?.item_id) {
          video = this.$refs[`video-${this.currentSlide.item_id}`];
        }

        if (video) {
          video.currentTime = 0;
          video.play();
        }
      },

      displaySlide(slide) {
        return this.currentSlide?.item_id === slide.item_id && !slide.item_type?.includes('video');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/sass/animations.scss';

  .playlist-player {
    height: 100%;
    overflow: hidden;
  }

  .slideIn {
    transform: scaleX(1);
    transform-origin: right;
    transition:
      0.5s transform ease-in,
      0.5s visibility ease-in;
  }

  .slideOut {
    transform: scaleX(0);
    transform-origin: left;
    transition:
      0.5s transform ease-in,
      0.5s visibility ease-in;
  }

  .fadeIn,
  .fadeOut {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
</style>

<style lang="scss">
  .playlist-slide {
    position: absolute;
    overflow: hidden;
  }

  .playlist-slide-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .playlist-slide {
    width: 100%;
    height: 100%;

    .playlist-slide-image,
    .playlist-slide-video,
    .playlist-slide-app-rss,
    .playlist-slide-app-table,
    .playlist-slide-trafiklab-table,
    .playlist-slide-weather-table,
    .player-slide-youtube {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .playlist-slide-video {
      &.stretch {
        object-fit: fill;
      }

      &.origin {
        object-fit: none;
      }
    }

    .player-slide-youtube {
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .playlist-background {
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255, 0) url('../../assets/img/layoutDesigner/playlist.png') no-repeat
      center;
    background-size: 70px;
  }

  .video-preview {
    width: 100%;
    height: 100%;
  }
</style>
