import config from '../config';
import axios from 'axios';

axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token');

export const getFontsApi = async () => {
  let apiUrl = `${config.apiUrl}userapi/fonts/`;

  return await axios.get(apiUrl);
};

export const uploadFontApi = async (fontData) => {
  let apiUrl = `${config.apiUrl}userapi/fonts`;

  return await axios.post(apiUrl, fontData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export const deleteFontApi = async (fontId) => {
  let apiUrl = `${config.apiUrl}userapi/fonts/${fontId}`;

  return await axios.delete(apiUrl);
};
