import config from '../config'
import axios from 'axios'

axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token')

export const apiGetEventlog = () => new Promise((resolve, reject) => {
  var apiUrl = `${config.apiUrl}userapi/eventlog`
  //console.log(`GET ${apiUrl}`)
  var p = axios.get(apiUrl).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
  return p
})
