import {
    RSS_APP_CREATE,
    RSS_APP_CREATE_SUCCESS,
    RSS_APP_CREATE_ERROR,
    RSS_APP_DELETE,
    APP_CREATE,
    APP_CREATE_SUCCESS,
    APP_CREATE_ERROR,
    APP_DELETE,
    APP_UPDATE
} from '../actions/apps';

import {
    apiCreateApp,
    apiDeleteApp,
    apiUpdateApp
} from '../../api/apps'

const getters = {
}

const actions = {
    [RSS_APP_CREATE]: ({commit}, setting) => {
        commit(RSS_APP_CREATE);

        var p = apiCreateApp(setting);
        p.then((resp) => {
            commit(RSS_APP_CREATE_SUCCESS, resp)
        }).catch((error) => {
            commit(RSS_APP_CREATE_ERROR, error)
        });
        return p
    },
    [RSS_APP_DELETE]: ({commit}, setting) => {
        commit(RSS_APP_DELETE);
        var p = apiDeleteApp(setting);
        p.then((resp) => {
            commit(RSS_APP_CREATE_SUCCESS, resp)
        }).catch((error) => {
            commit(RSS_APP_CREATE_ERROR, error)
        });
        return p
    },
    [APP_CREATE]: ({commit}, setting) => {
        commit(APP_CREATE);
        var p = apiCreateApp(setting);
        p.then((resp) => {
            commit(APP_CREATE_SUCCESS, resp)
        }).catch((error) => {
            commit(APP_CREATE_ERROR, error)
        });
        return p
    },
    [APP_DELETE]: ({commit}, setting) => {
        commit(APP_DELETE);
        var p = apiDeleteApp(setting);
        return p;
    },
    [APP_UPDATE]: ({commit}, setting) => {
        commit(APP_UPDATE);
        var p = apiUpdateApp(setting);
        return p;
    },
};

const state = {
    appCreateStatus: false
};

const mutations = {
    [RSS_APP_CREATE]: (state) => {
        state.appCreateStatus = 'loading'
    },
    [RSS_APP_CREATE_SUCCESS]: (state, resp) => {
        state.appCreateStatus = 'success';
    },
    [RSS_APP_CREATE_ERROR]: (state, error) => {
        state.appCreateStatus = 'error';
    },
    [APP_CREATE]: (state) => {
        state.appCreateStatus = 'loading'
    },
    [APP_CREATE_SUCCESS]: (state, resp) => {
        state.appCreateStatus = 'success';
    },
    [APP_CREATE_ERROR]: (state, error) => {
        state.appCreateStatus = 'error';
    },
    [APP_DELETE]: (state) => {
        //state.appCreateStatus = 'loading'
    },
    [APP_UPDATE]: (state) => {
        //state.appCreateStatus = 'loading'
    },
};

export default {
    getters,
    state,
    actions,
    mutations
}
