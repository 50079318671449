<template>
  <WidgetNode
    :node="getWidgetNode(widget)"
    class="widget-item"
    :class="{ selected: isWidgetSelected }"
  />
</template>

<script>
  import { simpleTypeMixin } from '@/helpers';
  import WidgetNode from '@/components/playerWidgets/WidgetNode.vue';
  import { WIDGET_TYPES } from '@/models/layoutDesigner';

  export default {
    props: {
      widget: {
        type: Object,
        default: () => null,
      },
    },

    mixins: [simpleTypeMixin],

    components: {
      WidgetNode,
    },

    computed: {
      isWidgetSelected() {
        const selectedWidget = this.$store.state.player.selectedWidget;

        if (!selectedWidget) return;

        const selectedItemId =
          selectedWidget.itemType === WIDGET_TYPES.PLAYLIST
            ? selectedWidget.object.playlist_id
            : selectedWidget.object.item_id || selectedWidget.object.template_id;

        const itemId =
          this.widget.itemType === WIDGET_TYPES.PLAYLIST
            ? this.widget.object.playlist_id
            : this.widget.object.item_id || this.widget.object.template_id;

        const isWidgetSelected = itemId === selectedItemId;

        return isWidgetSelected;
      },
    },

    methods: {
      getWidgetNode(widget) {
        return {
          data: widget,
          id: widget.object.type || WIDGET_TYPES.PLAYLIST,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .selected {
    z-index: 1500 !important;
    background-color: rgb(255, 255, 255);
  }
  .widget-item {
    cursor: pointer;
  }
</style>
