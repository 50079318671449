export const ORGANISATION_REQUEST = 'ORGANISATION_REQUEST';
export const ORGANISATION_UPDATE = 'ORGANISATION_UPDATE';
export const SET_CURRENT_ORGANISATION = 'SET_CURRENT_ORGANISATION';

export const ORGANISATION_UPDATE_SUCCESS = 'ORGANISATION_UPDATE_SUCCESS';
export const GET_ORGANISATION_AS_ADMIN = 'GET_ORGANISATION_AS_ADMIN';
export const ADMIN_ORGANISATIONS_GET = 'ADMIN_ORGANISATIONS_GET';
export const SET_ORGANISATIONS = 'SET_ORGANISATIONS';

export const ADMIN_ORGANISATION_PUT = 'ADMIN_ORGANISATION_PUT';
export const ADMIN_ORGANISATION_DELETE = 'ADMIN_ORGANISATION_DELETE';
export const ADMIN_ORGANISATION_UPDATE = 'ADMIN_ORGANISATION_UPDATE';
