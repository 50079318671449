<template>
  <div class="p-3 d-flex justify-content-center">
    <img :src="loadingImg" alt="Preloading image" style="height: 64px;" />
  </div>
</template>

<script>
import loadingImg from '@/assets/img/rss/preloader.svg';
export default {
  name: "PreLoader",

  data() {
    return {
      loadingImg
    }
  }
}
</script>
