import config from '../config'
import axios from 'axios'

axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-session_token')

export const apiGetUser = (user_id) => new Promise((resolve, reject) => {
  var apiUrl = ''
  if (user_id) {
    apiUrl = `${config.apiUrl}userapi/user/${user_id}`
  } else {
    apiUrl = `${config.apiUrl}userapi/user`
  }

  axios.get(apiUrl).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
})

export const apiAddUser = (user) => new Promise((resolve, reject) => {
  var apiUrl = `${config.apiUrl}userapi/user`
  axios.put(apiUrl, user).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
})

export const apiDeleteUser = (user_id) => new Promise((resolve, reject) => {
  var apiUrl = `${config.apiUrl}userapi/user/${user_id}`
  axios.delete(apiUrl).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
})

export const apiUpdateUser = (user) => new Promise((resolve, reject) => {
  var apiUrl = `${config.apiUrl}userapi/user/${user.user_id}`
  axios.post(apiUrl, user).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
})

export const apiUpdateUserAvatar = (user) => new Promise((resolve, reject) => {
  const formData = new FormData();
  formData.append('avatar', user.avatar);

  let apiUrl = `${config.apiUrl}userapi/user/${user.user_id}/avatar`;
  const configA = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  axios.post(apiUrl, formData, configA).then((resp) => {
    resolve(resp);
  }).catch((error) => {
    reject(error);
  });
});

export const adminApiPutUser = (userData) => new Promise((resolve, reject) => {
  var apiUrl = `${config.apiUrl}adminapi/user`
  axios.put(apiUrl, userData).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
})

export const adminApiGetUsers = (orgId) => new Promise((resolve, reject) => {
  var apiUrl = `${config.apiUrl}adminapi/user/${orgId}`
  axios.get(apiUrl).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
})

export const adminApiPostUser = (user_id, userData) => new Promise((resolve, reject) => {
  const apiUrl = `${config.apiUrl}adminapi/user/${user_id}`

  if( ! userData.password) {
    userData['password'] = '';
  }
  axios.post(apiUrl, userData).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
})

export const adminApiDeleteUser = (user_id) => new Promise((resolve, reject) => {
  var apiUrl = `${config.apiUrl}adminapi/user/${user_id}`

  axios.delete(apiUrl).then((resp) => {
    resolve(resp)
  }).catch((error) => {
    reject(error)
  })
})

