<script>
  import BaseText from '@ui/atoms/baseText/BaseText.vue';

  export default {
    name: 'ErrorState',
    components: { BaseText },
    props: {
      title: {
        type: String,
        default: 'Failed to load widget',
      },
      content: {
        type: String,
        default: 'An error has occurred.',
      },
    },
  };
</script>

<template>
  <div class="container">
    <img class="icon" src="@/assets/icon/warning/warning.svg" alt="warning" />
    <BaseText variant="h6" color="#151515">{{ title }}</BaseText>
    <BaseText color="#333332">{{ content }}</BaseText>
  </div>
</template>

<style scoped lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12px;
    width: 100%;
    height: 100%;
    background: white;
  }

  .icon {
    width: 50px;
    height: 44px;
    margin-bottom: 12px;
  }
</style>
