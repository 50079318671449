export enum TextVariant {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  Subtitle1 = 'subtitle1',
  Subtitle2 = 'subtitle2',
  Body1 = 'body1',
  Body2 = 'body2',
  Caption1 = 'caption1',
  InputLabel = 'inputLabel',
  Title1 = 'title1',
}

export const TEXT_CLASS: Record<TextVariant, string> = {
  [TextVariant.H1]: 'text-h1',
  [TextVariant.H2]: 'text-h2',
  [TextVariant.H3]: 'text-h3',
  [TextVariant.H4]: 'text-h4',
  [TextVariant.H5]: 'text-h5',
  [TextVariant.H6]: 'text-h6',
  [TextVariant.Subtitle1]: 'text-subtitle1',
  [TextVariant.Subtitle2]: 'text-subtitle2',
  [TextVariant.Body1]: 'text-body1',
  [TextVariant.Body2]: 'text-body2',
  [TextVariant.Caption1]: 'text-caption',
  [TextVariant.InputLabel]: 'inputLabel',
  [TextVariant.Title1]: 'text-title-1',
} as const;
