export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_SUPER_ADMIN = 'SET_SUPER_ADMIN_TOKEN';

export const SET_VIEW_AS_USER = 'SET_VIEW_AS_USER';

export const VIEW_AS_USER = 'VIEW_AS_USER';
export const VIEW_AS_USER_SUCCESS = 'VIEW_AS_USER_SUCCESS';
export const VIEW_AS_USER_ERROR = 'VIEW_AS_USER_ERROR';

export const VIEW_AS_USER_EXIT = 'VIEW_AS_USER_EXIT';
export const VIEW_AS_USER_EXIT_SUCCESS = 'VIEW_AS_USER_EXIT_SUCCESS';
export const VIEW_AS_USER_EXIT_ERROR = 'VIEW_AS_USER_EXIT_ERROR';

export const SET_USER_LOCAL_ADMIN = 'SET_USER_LOCAL_ADMIN';
